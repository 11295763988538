import { createBrowserRouter } from "react-router-dom"
import { pages } from "../constants"
import { BenefitsFormPage } from "./BenefitsFormPage/BenefitsFormPage"
import { BenefitsPage } from "./BenefitsPage/BenefitsPage"
import { DocsFormPage } from "./DocsFormPage/DocsFormPage"
import { EmployeesFormPage } from "./EmployeesFormPage/EmployeesFormPage"
import { EmployeesPage } from "./EmployeesPage/EmployeesPage"
import { LoginPage } from "./LoginPage/LoginPage"
import { MenuPage } from "./MenuPage/MenuPage"
import { NewsFormPage } from "./NewsFormPage/NewsFormPage"
import { NewsPage } from "./NewsPage/NewsPage"
import { MenuFormPage } from "./MenuFormPage/MenuFormPage"
import { CheckListPage } from "./CheckListPage/CheckListPage"
import { ProfilePage } from "./ProfilePage/ProfilePage"
import { adminProtection } from "../utils"
import { DictPage } from "./DictPage/DictPage"
import { MenuListPage } from "./MenuListPage/MenuListPage"
import { CategoryFormPage } from "./CategoryFormPage/CategoryFormPage"
import { CategoryListPage } from "./CategoryListPage/CategoryListPage"
import { CategoryPage } from "./CategoryPage/CategoryPage"

export const router = createBrowserRouter([
  {
    path: pages.default,
    Component: LoginPage,
  },
  // admin pages
  {
    loader: adminProtection,
    children: [
      {
        path: pages.news,
        Component: NewsPage,
        loader: adminProtection,
      },
      {
        path: pages.newsItem,
        Component: NewsFormPage,
      },
      {
        path: pages.employees,
        Component: EmployeesPage,
      },
      {
        path: pages.employeesItem,
        Component: EmployeesFormPage,
      },
      {
        path: pages.docsItem,
        Component: DocsFormPage,
      },
      {
        path: pages.benefits,
        Component: BenefitsPage,
      },
      {
        path: pages.benefitsItem,
        Component: BenefitsFormPage,
      },
      {
        path: pages.menu,
        Component: MenuPage,
      },
      {
        path: pages.menuItem,
        Component: MenuFormPage,
      },
      {
        path: pages.category,
        Component: CategoryPage,
      },
      {
        path: pages.categoryItem,
        Component: CategoryFormPage,
      },
    ],
  },
  // employee pages
  {
    path: pages.profile,
    Component: ProfilePage,
  },
  {
    path: pages.checkList,
    Component: CheckListPage,
  },
  {
    path: pages.dict,
    Component: DictPage,
  },
  {
    path: pages.menuList,
    Component: MenuListPage,
  },
  {
    path: pages.categoryList,
    Component: CategoryListPage,
  },
])
