import Title from "antd/es/typography/Title"
import styles from "./NewsPage.module.css"
import { Button, Radio, RadioChangeEvent, Skeleton } from "antd"
import { PlusOutlined, SearchOutlined } from "@ant-design/icons"
import { useEffect, useState } from "react"
import Search from "antd/es/input/Search"
import { defaultPagination, newsTargets, pages } from "../../constants"
import { NewsCard } from "./NewsCard"
import { MainLayout } from "../../components/MainLayout/MainLayout"
import { INewsListPagination } from "../../types"
import { useGetNews } from "../../queries/news/useGetNews"
import { Link } from "react-router-dom"
import debounce from "lodash/debounce"
import { useInView } from "react-intersection-observer"

export const NewsPage = () => {
  const { ref, inView } = useInView()
  const [pagination, setPagination] =
    useState<INewsListPagination>(defaultPagination)
  const { data, isLoading, hasNextPage, fetchNextPage } = useGetNews(pagination)

  const buttonsList = Object.keys(newsTargets) as (keyof typeof newsTargets)[]

  const handleChangePagination = debounce(
    (event: string | RadioChangeEvent) => {
      setPagination((prev) => {
        let key = "search"
        let value = event

        if (event instanceof Object) {
          key = event.target.name as string
          value = event.target.value as string
        }

        return { ...prev, [key]: value }
      })
    },
    200,
  )

  useEffect(() => {
    if (inView && !isLoading) {
      fetchNextPage()
    }
  }, [fetchNextPage, inView, isLoading])

  return (
    <MainLayout>
      <div className={styles.page}>
        <div className={styles.header}>
          <Title level={4}>Новости</Title>
          <Link to={`${pages.news}/create`}>
            <Button type="primary" icon={<PlusOutlined />}>
              Создать новость
            </Button>
          </Link>
        </div>
        <div className={styles.content}>
          <div className={styles.filters}>
            <Radio.Group
              name="target"
              disabled={isLoading}
              value={pagination.target}
              onChange={handleChangePagination}
            >
              <Radio.Button>Все</Radio.Button>
              {buttonsList.map((buttonItem) => (
                <Radio.Button key={buttonItem} value={buttonItem}>
                  {newsTargets[buttonItem].title}
                </Radio.Button>
              ))}
            </Radio.Group>
            <Search
              name="search"
              className={styles.input}
              placeholder="Поиск по новостям"
              enterButton="Искать"
              loading={isLoading}
              onSearch={handleChangePagination}
              suffix={<SearchOutlined />}
            />
          </div>
          <div className={styles.list}>
            {data?.pages.map((page) =>
              page?.list?.map((news) => <NewsCard key={news.id} {...news} />),
            )}
            {hasNextPage && (
              <div ref={ref} className={styles.card}>
                <Skeleton active />
              </div>
            )}
          </div>
        </div>
      </div>
    </MainLayout>
  )
}
