import { UseBaseMutationResult, useMutation, useQueryClient } from "react-query"
import { EMPLOYEES } from "../keys"
import { EmployeeService } from "../services/Employee.service"

export function useDeleteEmployee(
  id: string,
): UseBaseMutationResult<string, unknown, void> {
  const queryClient = useQueryClient()

  return useMutation(() => EmployeeService.deleteEmployee(id), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(EMPLOYEES)
    },
  })
}
