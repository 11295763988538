import { QueryObserverResult, useQuery } from "react-query"
import { IMenuListPagination, IMenuListRes } from "../../types"
import { MENU } from "../keys"
import { MenuService } from "../services/Menu.service"

export function useGetMenu(
  pagination: IMenuListPagination,
  enabled = true,
): QueryObserverResult<IMenuListRes> {
  return useQuery(
    [MENU, pagination.page, pagination.pageSize, pagination.search],
    () => MenuService.getMenu(pagination),
    { enabled },
  )
}
