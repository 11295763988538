import styles from "./MainLayout.module.css"
import { default as OrengoLogo } from "../../assets/orengo-logo.svg"
import { MenuList } from "../MenuList/MenuList"
import { FC, ReactNode } from "react"
import { Button, Skeleton } from "antd"
import { useLogout } from "../../queries/auth/useLogout"

interface IMainLayout {
  children?: ReactNode
}

export const MainLayout: FC<IMainLayout> = (props) => {
  const { mutate, isLoading } = useLogout()

  return (
    <main className={styles.main}>
      <header className={styles.header}>
        <img className={styles.logoImage} src={OrengoLogo} alt="logo" />
        {isLoading ? (
          <Skeleton.Button active />
        ) : (
          <Button className={styles.logout} type="text" onClick={mutate}>
            Выйти
          </Button>
        )}
      </header>
      <div className={styles.content}>
        <MenuList />
        {props?.children}
      </div>
    </main>
  )
}
