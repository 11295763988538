import request from "axios"

export const BACKEND_URL = import.meta.env.VITE_BACKEND_URL

request.defaults.baseURL = BACKEND_URL
request.defaults.headers["Access-Control-Allow-Origin"] = "*"

export const refreshTokenRequest = async (token: string) => {
  const response = await request.get("/auth/refresh", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  return response.data
}

request.interceptors.request.use(
  (config): any => {
    if (config.url) {
      if (config.url === "/auth/logout") {
        localStorage.clear()
      }

      if (["/auth/refresh", "/auth/login"].includes(config.url)) {
        return config
      }
    }

    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    }
  },
  (error) => {
    return Promise.reject(error)
  },
)

request.interceptors.response.use(
  ({ data }) => {
    if (data?.jwt) {
      localStorage.setItem("accessToken", data?.jwt?.accessToken)
      localStorage.setItem("refreshToken", data?.jwt?.refreshToken)
    }

    return data
  },
  async (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem("accessItem")

      const refreshToken = localStorage.getItem("refreshToken")

      if (refreshToken) {
        localStorage.removeItem("refreshToken")

        await request.get("/auth/refresh", {
          headers: {
            Authorization: `Bearer ${refreshToken}`,
          },
        })

        const prevRequest = await request.request(error.config)

        return prevRequest.data
      }

      localStorage.clear()

      const loginPage = `${window.location.origin}/`

      if (window.location.href !== loginPage) {
        window.location.replace(loginPage)
      }
    }

    return Promise.reject(error)
  },
)

export { request }
