import { FC, Fragment } from "react"
import { INews } from "../../types"
import { newsTargets, pages } from "../../constants"
import Title from "antd/es/typography/Title"
import Paragraph from "antd/es/typography/Paragraph"
import Text from "antd/es/typography/Text"
import Link from "antd/es/typography/Link"
import styles from "./NewsPage.module.css"
import dayjs from "dayjs"
import { DeleteOutlined, EditOutlined } from "@ant-design/icons"
import { Button, Modal } from "antd"
import { Link as RouterLink } from "react-router-dom"
import { useDeleteNews } from "../../queries/news/useDeleteNews"
import { deleteNewsModal } from "../../components/Modals/deleteNewsModal"
import { BigImage } from "../../components/BigImage/BigImage"
import { BigVideo } from "../../components/BigVideo/BigVideo"

export const NewsCard: FC<INews & { hideControl?: boolean }> = ({
  id,
  title,
  target,
  text,
  link,
  images,
  videos,
  createdAt,
  hideControl,
}) => {
  const [modal, contextHolder] = Modal.useModal()

  const { mutateAsync: deleteNews, isLoading } = useDeleteNews(id)

  return (
    <div className={styles.card}>
      <div className={styles.card_top}>
        <div className={styles.card_text}>
          <Title level={5}>{title}</Title>
          <Text code>{newsTargets[target]?.title}</Text>
          <Paragraph>{text}</Paragraph>
          <div className={styles.info}>
            <div>
              <Text type="secondary">ссылка: </Text>
              <Link href={link} target="_blank">
                {link}
              </Link>
            </div>
            <Text disabled>{dayjs(createdAt).format("YYYY-MM-DD HH:mm")}</Text>
          </div>
        </div>
        <div className={styles.card_images}>
          {videos?.map((video) => (
            <BigVideo key={video.uid} {...video} />
          ))}
          {link && (
            <BigVideo key={link} url={link} name="link_video" uid={link} />
          )}
          {images?.map((image) => (
            <BigImage key={image.uid} {...image} />
          ))}
        </div>
      </div>

      {hideControl ? (
        <Fragment />
      ) : (
        <div className={styles.buttons}>
          <RouterLink to={`${pages.news}/${id}`}>
            <Button icon={<EditOutlined />}>Редактировать</Button>
          </RouterLink>
          <Button
            type="primary"
            danger
            onClick={deleteNewsModal(modal, deleteNews)}
            icon={<DeleteOutlined />}
            disabled={isLoading}
          >
            Удалить
          </Button>
          {contextHolder}
        </div>
      )}
    </div>
  )
}
