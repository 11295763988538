import { UseBaseMutationResult, useMutation, useQueryClient } from "react-query"
import { IEmployee, ILoginReq } from "../../types"
import { AuthService } from "../services/Auth.service"
import { useNavigate } from "react-router-dom"
import { ADMIN_POSITION, pages } from "../../constants"
import { USER } from "../keys"
import { AxiosError } from "axios"

export function useLogin(): UseBaseMutationResult<
  IEmployee,
  AxiosError,
  ILoginReq
> {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  return useMutation(AuthService.login, {
    onSuccess: async (user) => {
      queryClient.setQueryData(USER, user)

      navigate(user?.position === ADMIN_POSITION ? pages.news : pages.profile)
    },
  })
}
