import { Button, Modal } from "antd"
import { FC } from "react"
import { IEmployee } from "../../types"
import { showEmployeeModal } from "../../components/Modals/showEmployeeModal"

interface IDictPageActionProps {
  employee: IEmployee
  hideInfo?: boolean
}

export const DictPageAction: FC<IDictPageActionProps> = ({
  employee,
  hideInfo = true,
}) => {
  const [modal, contextHolder] = Modal.useModal()

  return (
    <div>
      <Button
        type="link"
        onClick={showEmployeeModal(modal, employee, hideInfo)}
      >
        {employee.name}
      </Button>
      {contextHolder}
    </div>
  )
}
