import { IPagination } from "../types"

export const CREATE_ID = "create"
export const ADMIN_POSITION = "admin" as const

export const pages = {
  default: "/",
  profile: "/profile",
  checkList: "/check-list",
  news: "/news",
  newsItem: "/news/:id",
  employees: "/employees",
  employeesItem: "/employees/:id",
  benefits: "/benefits",
  benefitsItem: "/benefits/:id",
  menu: "/menu",
  menuItem: "/menu/:id",
  docs: "/docs",
  docsItem: "/docs/:id",
  dict: "/dict",
  menuList: "/menu-list",
  categoryList: "/category-list",
  category: "/category",
  categoryItem: "/category/:id",
}

export const newsTargets = {
  all: { key: "all", title: "Для всех" },
  for_clients: { key: "for_clients", title: "Для клиентов" },
  for_employee: { key: "for_employee", title: "Для сотрудников" },
} as const

export const positionTargets = {
  bartender: { key: "bartender", title: "Бармен" },
  manager: { key: "manager", title: "Менеджер" },
  confectioner: { key: "confectioner", title: "Кондитер" },
} as const

export const positionTargetsFilter = {
  all: { key: "all", title: "Все" },
  bartender: { key: "bartender", title: "Бармены" },
  manager: { key: "manager", title: "Менеджеры" },
  confectioner: { key: "confectioner", title: "Кондитеры" },
  employee: { key: "employee", title: "Сотрудник" },
} as const

export const benefitTypes = {
  bonus: { key: "bonus", title: "Премия" },
  penalty: { key: "penalty", title: "Взыскание" },
} as const

export const ItemVisibilityStatuses = {
  on_site: { key: "on_site", title: "На сайте" },
  hidden: { key: "hidden", title: "Скрыт" },
} as const

export const menuStatusesForm = [
  { key: "on_site", title: "Показать на сайте" },
  { key: "hidden", title: "Скрыть" },
] as const

export const defaultPagination: IPagination = {
  page: 0,
  pageSize: 20,
}

export const validateMessages = {
  required: "обязательно для заполнения",
}

export const blocks = [
  { value: "Приэльбрусье, здание гостиницы «Azau Star»" },
  { value: "Нальчик, здание гостиницы «Azau Star»" },
  { value: "Баксан, здание гостиницы «Azau Star»" },
]

export const filterPositions = [
  {
    key: "all",
    label: "Все",
  },
  ...Object.values(positionTargets).map((position) => ({
    key: position.key,
    label: position.title,
  })),
]

export const filterBlocks = [
  {
    key: "all",
    label: "Все подразделения",
  },
  ...blocks.map((block) => ({
    key: block.value,
    label: block.value,
  })),
]
