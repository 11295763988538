import { QueryObserverResult, useQuery } from "react-query"
import { INews } from "../../types"
import { NEWS } from "../keys"
import { NewsService } from "../services/News.service"
import { CREATE_ID } from "../../constants"

export function useGetNewsById(id?: string): QueryObserverResult<INews> {
  return useQuery([NEWS, id], () => NewsService.getNewsById(id), {
    enabled: !!(id && id !== CREATE_ID),
  })
}
