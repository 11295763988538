import { Link } from "react-router-dom"
import { pages } from "../../constants"

// const regex = /(?<=\/)(.{1,})(?=\/)/gm

export const generateBreadcrumbItems = (
  isCreate: boolean,
  pathname: string,
) => {
  const path = pathname?.split("/")?.[1] ?? "";
  
  switch (`/${path}`) {
    case pages.news:
      return [
        {
          title: <Link to={pages.news}>Новости</Link>,
        },
        {
          title: isCreate ? "Создать новость" : "Редактировать новость",
        },
      ]
    case pages.employees:
      return [
        {
          title: <Link to={pages.employees}>Сотрудники</Link>,
        },
        {
          title: isCreate ? "Добавить" : "Редактировать",
        },
      ]
    case pages.docs:
      return [
        {
          title: <Link to={pages.employees}>Сотрудники</Link>,
        },
        {
          title: isCreate ? "Добавить документ" : "Редактировать документ",
        },
      ]
    case pages.menu:
      return [
        {
          title: <Link to={pages.menu}>Меню</Link>,
        },
        {
          title: isCreate
            ? "Добавить позицию в меню"
            : "Редактировать позицию меню",
        },
      ]
    case pages.category:
      return [
        {
          title: <Link to={pages.category}>Категории</Link>,
        },
        {
          title: isCreate
            ? "Добавить позицию в категории"
            : "Редактировать позицию категорий",
        },
      ]
    case pages.benefits:
      return [
        {
          title: <Link to={pages.benefits}>Премии / Взыскания</Link>,
        },
        {
          title: isCreate
            ? "Добавить премию или взысканние"
            : "Редактировать премию или взысканние",
        },
      ]
  }
}
