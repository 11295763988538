import Title from "antd/es/typography/Title"
import styles from "./BenefitsFormPage.module.css"
import { MainLayout } from "../../components/MainLayout/MainLayout"
import { useParams } from "react-router-dom"
import {
  CREATE_ID,
  benefitTypes,
  blocks,
  defaultPagination,
  positionTargetsFilter,
} from "../../constants"
import {
  IBenefit,
  ICreateBenefitReq,
  IEmployeeListPagination,
} from "../../types"
import { FormCard } from "../../components/FormCard/FormCard"
import { Button, Form, Input, InputNumber, Radio, Select } from "antd"
import { FormButtons } from "../../components/FormCard/FormButtons"
import { Breadcrumbs } from "../../components/Breadcrumbs/Breadcrumbs"
import { useGetBenefitsById } from "../../queries/benefits/useGetBenefitsById"
import { useCreateBenefits } from "../../queries/benefits/useCreateBenefits"
import { useUpdateBenefits } from "../../queries/benefits/useUpdateBenefits"
import { Fragment, useState } from "react"
import { useGetEmployees } from "../../queries/employee/useGetEmployees"
import debounce from "lodash/debounce"

const options = [
  ...blocks.map(({ value }) => ({ value, label: value })),
  { value: "all", label: "Все подразделения" },
]

export const BenefitsFormPage = () => {
  const { id } = useParams()
  const isCreate = id === CREATE_ID

  const [form] = Form.useForm()
  const target = Form.useWatch("target", form)

  const [pagination, setPagination] =
    useState<IEmployeeListPagination>(defaultPagination)

  const { data: employees, isLoading: isGetEmployeesLoading } =
    useGetEmployees(pagination)
  const { data, isLoading: isGetBenefitsByIdLoading } = useGetBenefitsById(id)

  const { mutate: saveBenefits, isLoading: isCreateBenefitsLoading } =
    useCreateBenefits()
  const { mutate: updateBenefits, isLoading: isUpdateBenefitsLoading } =
    useUpdateBenefits(id as string)

  const onFinish = (values: ICreateBenefitReq | Omit<IBenefit, "id">) => {
    if (isCreate) {
      saveBenefits(values)
    } else {
      updateBenefits({
        type: (values as IBenefit).type,
        description: values.description,
        amount: values.amount,
      })
    }
  }

  const debounceFetcher = debounce((value) => {
    setPagination((prev) => ({ ...prev, name: value }))
  }, 300)

  return (
    <MainLayout>
      <div className={styles.page}>
        <div className={styles.header}>
          <Breadcrumbs />
          <Title level={4}>
            {isCreate
              ? "Добавить премию или взысканние"
              : "Редактировать премию или взысканние"}
          </Title>
        </div>
        <div className={styles.content}>
          <FormCard
            key={data?.id}
            form={form}
            disabled={
              isGetBenefitsByIdLoading ||
              isCreateBenefitsLoading ||
              isUpdateBenefitsLoading
            }
            name="benefit"
            onFinish={onFinish}
            initialValues={
              data
                ? { ...data, userId: data.user?.id }
                : {
                    target: positionTargetsFilter.all.key,
                    block: "all",
                  }
            }
          >
            {isCreate ? (
              <Form.Item
                name={"target"}
                label="Для кого"
                rules={[{ required: true }]}
              >
                <Radio.Group disabled={!isCreate}>
                  {Object.values(positionTargetsFilter).map((position) => (
                    <Radio.Button key={position.key} value={position.key}>
                      {position.title}
                    </Radio.Button>
                  ))}
                </Radio.Group>
              </Form.Item>
            ) : (
              <Fragment />
            )}
            {isCreate && target !== positionTargetsFilter.employee.key ? (
              <Form.Item
                name={"block"}
                label="Подразделение"
                rules={[{ required: true }]}
              >
                <Select
                  placeholder="Выберите подразделение"
                  options={options}
                />
              </Form.Item>
            ) : (
              <Fragment />
            )}
            {!isCreate || target === positionTargetsFilter.employee.key ? (
              <Form.Item
                name={"userId"}
                label="Выберите сотрудника"
                rules={[{ required: true }]}
              >
                <Select
                  showSearch
                  placeholder="Начните вводить или выберите из списка"
                  loading={isGetEmployeesLoading}
                  onSearch={debounceFetcher}
                  options={employees?.list.map(({ id, name }) => ({
                    value: id,
                    label: name,
                  }))}
                />
              </Form.Item>
            ) : (
              <Fragment />
            )}

            <Form.Item name={"type"} label="Тип" rules={[{ required: true }]}>
              <Radio.Group>
                {Object.values(benefitTypes).map((benefitType) => (
                  <Radio key={benefitType.key} value={benefitType.key}>
                    {benefitType.title}
                  </Radio>
                ))}
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name={"description"}
              label="Описание (повод)"
              rules={[{ required: true }]}
            >
              <Input.TextArea placeholder="Введите описание, если нужно" />
            </Form.Item>
            <Form.Item
              name={"amount"}
              label="Сумма"
              rules={[{ required: true }]}
            >
              <InputNumber
                placeholder="Только число в ₽ без пробелов"
                addonBefore="₽"
                style={{ width: "100%" }}
              />
            </Form.Item>
            <FormButtons>
              <Button type="primary" htmlType="submit">
                {isCreate ? "Добавить" : "Сохранить"}
              </Button>
              <Button htmlType="reset">Сбросить</Button>
            </FormButtons>
          </FormCard>
        </div>
      </div>
    </MainLayout>
  )
}
