import { UseBaseMutationResult, useMutation } from "react-query"
import { ImageService } from "../services/Image.service"
import { UploadFile } from "antd"

export function useDeleteFile(): UseBaseMutationResult<
  void,
  unknown,
  UploadFile
> {
  return useMutation(ImageService.deleteImage)
}
