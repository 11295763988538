import { UploadOutlined } from "@ant-design/icons"
import { Button, Form, Upload, UploadFile } from "antd"
import { FC, useState } from "react"
import { useUploadFile } from "../../queries/media/useUploadFile"
import { useDeleteFile } from "../../queries/media/useDeleteFile"
import { TFileType } from "../../types"
import styles from "./UploadFile.module.css"
import { ImageService } from "../../queries/services/Image.service"

const normFile = (e: any) => {
  if (Array.isArray(e)) {
    return e
  }
  return e?.fileList
}

interface IUploadFileProps {
  name: string
  label: string
  text: string
  accept: string
  maxCount: number
  type: TFileType
}

export const UploadFileInput: FC<IUploadFileProps> = ({
  name,
  label,
  accept,
  text,
  maxCount,
  type,
}) => {
  const { mutateAsync: uploadFile, isError } = useUploadFile(type)
  const { mutateAsync: deleteFile } = useDeleteFile()

  return (
    <>
      <Form.Item
        name={name}
        valuePropName="fileList"
        label={label}
        getValueFromEvent={normFile}
      >
        <Upload
          multiple
          accept={accept}
          listType="picture"
          maxCount={maxCount}
          customRequest={uploadFile}
          onRemove={(file) => {
            deleteFile(file)
          }}
        >
          <Button htmlType="button" icon={<UploadOutlined />}>
            {text}
          </Button>
        </Upload>
      </Form.Item>
    </>
  )
}
