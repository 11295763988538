import Title from "antd/es/typography/Title"
import styles from "./CategoryFormPage.module.css"
import { MainLayout } from "../../components/MainLayout/MainLayout"
import { useParams } from "react-router-dom"
import { CREATE_ID } from "../../constants"
import { ICategory } from "../../types"
import { FormCard } from "../../components/FormCard/FormCard"
import { Button, Form, Input, InputNumber, Radio, Select } from "antd"
import { FormButtons } from "../../components/FormCard/FormButtons"
import { Breadcrumbs } from "../../components/Breadcrumbs/Breadcrumbs"
import { useGetCategoryById } from "../../queries/category/useGetCategoryById"
import { useCreateCategory } from "../../queries/category/useCreateCategory"
import { useUpdateCategory } from "../../queries/category/useUpdateCategory"

export const CategoryFormPage = () => {
  const { id } = useParams()
  const isCreate = id === CREATE_ID
  const { data, isLoading: isGetCategoryByIdLoading } = useGetCategoryById(id)

  const { mutate: saveCategory, isLoading: isCreateCategoryLoading } =
    useCreateCategory()
  const { mutate: updateCategory, isLoading: isUpdateCategoryLoading } =
    useUpdateCategory(id as string)

  const onFinish = (values: ICategory) => {
    if (isCreate) {
      saveCategory(values)
    } else {
      updateCategory(values)
    }
  }

  return (
    <MainLayout>
      <div className={styles.page}>
        <div className={styles.header}>
          <Breadcrumbs />
          <Title level={4}>
            {isCreate ? "Добавление позиции" : "Редактирование позиции"}
          </Title>
        </div>
        <div className={styles.content}>
          <FormCard
            key={data?.name}
            disabled={
              isCreateCategoryLoading ||
              isUpdateCategoryLoading ||
              isGetCategoryByIdLoading
            }
            name="employee"
            onFinish={onFinish}
            initialValues={data}
          >
            <Form.Item
              name={"name"}
              label="Название на английском"
              rules={[{ required: true }]}
            >
              <Input placeholder="Введите название на английском" />
            </Form.Item>
            <Form.Item
              name={"displayName"}
              label="Название на русском"
              rules={[{ required: true }]}
            >
              <Input placeholder="Введите название на русском" />
            </Form.Item>
            <Form.Item
              name={"order"}
              label="Порядок кнопки на сайте"
              rules={[{ required: true }]}
            >
              <InputNumber
                placeholder="Только число"
                style={{ width: "100%" }}
              />
            </Form.Item>
            <FormButtons>
              <Button type="primary" htmlType="submit">
                {isCreate ? "Добавить" : "Сохранить"}
              </Button>
              <Button htmlType="reset">Сбросить</Button>
            </FormButtons>
          </FormCard>
        </div>
      </div>
    </MainLayout>
  )
}
