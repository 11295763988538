import { QueryObserverResult, useQuery } from "react-query"
import { IEmployeeListPagination, IEmployeeListRes } from "../../types"
import { EMPLOYEES } from "../keys"
import { EmployeeService } from "../services/Employee.service"

export function useGetEmployees(
  pagination: IEmployeeListPagination,
  isAdmin = false,
  enabled = true,
): QueryObserverResult<IEmployeeListRes> {
  return useQuery(
    [
      EMPLOYEES,
      pagination.page,
      pagination.pageSize,
      pagination.name,
      pagination.position,
      pagination.block,
      pagination.isBirthdayToday,
      isAdmin,
    ],
    () =>
      isAdmin
        ? EmployeeService.getEmployeesAdmin(pagination)
        : EmployeeService.getEmployees(pagination),
    { enabled },
  )
}
