import { Typography } from "antd"
import { default as LoginBg } from "../../assets/login-bg.svg"
import { default as Logo } from "../../assets/logo.svg"

import styles from "./LoginPage.module.css"
import { LoginForm } from "./LoginForm"

export const LoginPage = () => {
  return (
    <div className={styles.page}>
      <img className={styles.bg_image} src={LoginBg} alt="logo" />
      <div className={styles.container}>
        <div className={styles.header}>
          <img className={styles.header_logo} src={Logo} alt="logo" />
          <Typography.Text className={styles.header_text}>
            Введите логин и пароль, чтобы войти в систему
          </Typography.Text>
        </div>
        <LoginForm />
      </div>
      <Typography.Text className={styles.copyright}>
        Copyright ©2023
      </Typography.Text>
    </div>
  )
}
