import { Tabs, TabsProps } from "antd"
import styles from "./BenefitsCard.module.css"
import { benefitTypes } from "../../constants"
import { BenefitsTable } from "./BenefitsTable"

const items: TabsProps["items"] = [
  {
    key: "all",
    label: "Всё",
    children: <BenefitsTable />,
  },
  {
    key: benefitTypes.bonus.key,
    label: "Премии",
    children: <BenefitsTable type={benefitTypes.bonus.key} />,
  },
  {
    key: benefitTypes.penalty.key,
    label: "Взыскания",
    children: <BenefitsTable type={benefitTypes.penalty.key} />,
  },
]
export const BenefitsCard = () => {
  return (
    <div className={styles.card}>
      <Tabs defaultActiveKey="all" items={items} />
    </div>
  )
}
