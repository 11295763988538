import { FC } from "react"
import { LockOutlined, UserOutlined } from "@ant-design/icons"
import { Button, Checkbox, Form, Input, Skeleton } from "antd"
import styles from "./LoginPage.module.css"
import { useLogin } from "../../queries/auth/useLogin"
import { ILoginReq } from "../../types"

export const LoginForm: FC = () => {
  const { mutate, isLoading, error, isError } = useLogin()

  const onFinish = (values: ILoginReq) => {
    mutate(values)
  }

  return (
    <Form name="login" initialValues={{ remember: true }} onFinish={onFinish}>
      <Form.Item
        name="login"
        rules={[{ required: true, message: "Введите логин!" }]}
      >
        <Input
          className={styles.input}
          prefix={<UserOutlined />}
          placeholder="логин"
        />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[{ required: true, message: "Введите пароль!" }]}
      >
        <Input
          className={styles.input}
          prefix={<LockOutlined />}
          type="password"
          placeholder="пароль"
        />
      </Form.Item>
      {isError ? <span className={styles.error}>{error.message}</span> : null}
      <Form.Item>
        <Form.Item name="remember" valuePropName="checked" noStyle>
          <Checkbox className={styles.checkbox}>Запомнить меня</Checkbox>
        </Form.Item>
      </Form.Item>

      <Form.Item>
        {isLoading ? (
          <Skeleton.Button className={styles.spin} active />
        ) : (
          <Button className={styles.button} type="primary" htmlType="submit">
            Войти
          </Button>
        )}
      </Form.Item>
    </Form>
  )
}
