import Title from "antd/es/typography/Title"
import styles from "./EmployeesPage.module.css"
import {
  Avatar,
  Button,
  Dropdown,
  Skeleton,
  Space,
  Typography,
  UploadFile,
} from "antd"
import {
  DownOutlined,
  PlusOutlined,
  SearchOutlined,
  UserOutlined,
} from "@ant-design/icons"
import { IEmployee, IEmployeeListPagination } from "../../types"
import Table, { ColumnsType, TablePaginationConfig } from "antd/es/table"
import dayjs from "dayjs"
import { MainLayout } from "../../components/MainLayout/MainLayout"
import { EmployeeDoc } from "./EmployeeDoc"
import {
  ADMIN_POSITION,
  defaultPagination,
  filterBlocks,
  filterPositions,
  pages,
  positionTargets,
} from "../../constants"
import { Link } from "react-router-dom"
import { useGetEmployees } from "../../queries/employee/useGetEmployees"
import { useState } from "react"
import { useGetProfile } from "../../queries/auth/useGetProfile"
import { EmployeesAction } from "./EmployeesAction"
import { useGetDoc } from "../../queries/docs/useGetDocs"
import { DictPageAction } from "../DictPage/DictPageAction"
import Checkbox, { CheckboxChangeEvent } from "antd/es/checkbox"
import Search from "antd/es/input/Search"

const columns: ColumnsType<IEmployee> = [
  {
    title: "ФИО",
    dataIndex: "name",
    key: "name",
    width: 300,
    render: (value, employee) => (
      <DictPageAction employee={employee} hideInfo={false} />
    ),
  },
  {
    title: "Фото",
    width: 70,
    dataIndex: "images",
    key: "images",
    render: (value: UploadFile[]) => (
      <Avatar src={value?.[0]?.url} size={32} icon={<UserOutlined />} />
    ),
  },
  {
    title: "Позиция",
    dataIndex: "position",
    width: 110,
    key: "position",
    render: (value: keyof typeof positionTargets) =>
      positionTargets[value].title,
  },
  {
    title: "ДР",
    dataIndex: "dateOfBirth",
    key: "dateOfBirth",
    width: 100,
    render: (value: string) => dayjs(value).format("DD.MM.YYYY"),
  },
  {
    title: "Телефон",
    dataIndex: "phone",
    key: "phone",
    width: 150,
  },
  {
    title: "Логин",
    dataIndex: "login",
    key: "login",
    width: 100,
  },
  {
    title: "Пароль",
    dataIndex: "password",
    key: "password",
    width: 100,
  },
  {
    title: "Подразделение",
    dataIndex: "block",
    key: "block",
  },
  {
    title: "Действия",
    key: "id",
    dataIndex: "id",
    fixed: "right",
    width: 120,
    render: (value: string) => <EmployeesAction employeeId={value} />,
  },
]

export const EmployeesPage = () => {
  const [pagination, setPagination] =
    useState<IEmployeeListPagination>(defaultPagination)

  const { data: profile, isSuccess } = useGetProfile()
  const { data: docs, isLoading: isGetDocLoading } = useGetDoc()

  const isAdmin = (profile?.position as string) === ADMIN_POSITION

  const { data: employees, isLoading: isGetEmployeesLoading } = useGetEmployees(
    pagination,
    isAdmin,
    isSuccess,
  )

  const handleTableChange = ({ current }: TablePaginationConfig) => {
    setPagination((prev) => ({
      ...prev,
      page: (current ?? 1) - 1,
    }))
  }

  const handleChangePagination =
    (field: string) =>
    (event: CheckboxChangeEvent | { key: string } | string) => {
      ;(event as CheckboxChangeEvent)?.preventDefault?.()

      setPagination((prev) => ({
        ...prev,
        [field]:
          (event as CheckboxChangeEvent)?.target?.checked ??
          (event as { key: string })?.key ??
          event,
      }))
    }

  return (
    <MainLayout>
      <div className={styles.page}>
        <div className={styles.header}>
          <Title level={4}>Сотрудники</Title>
          <Link to={`${pages.employees}/create`}>
            <Button type="primary" icon={<PlusOutlined />}>
              Добавить нового
            </Button>
          </Link>
        </div>
        <div className={styles.content}>
          <div className={styles.top}>
            <Title level={5}>Все сотрудники</Title>
            <div className={styles.filters}>
              <Checkbox
                checked={!!pagination.isBirthdayToday}
                onChange={handleChangePagination("isBirthdayToday")}
              >
                Сегодня день рождения
              </Checkbox>
              <Dropdown
                trigger={["click"]}
                menu={{
                  items: filterPositions,
                  onClick: handleChangePagination("position"),
                }}
              >
                <Typography.Text className={styles.dropdown}>
                  <Space>
                    Позиция
                    <DownOutlined />
                  </Space>
                </Typography.Text>
              </Dropdown>

              <Dropdown
                trigger={["click"]}
                menu={{
                  items: filterBlocks,
                  onClick: handleChangePagination("block"),
                }}
              >
                <Typography.Text className={styles.dropdown}>
                  <Space>
                    Подразделение
                    <DownOutlined />
                  </Space>
                </Typography.Text>
              </Dropdown>

              <Search
                style={{ width: 300 }}
                name="search"
                placeholder="Поиск сотрудника"
                enterButton="Искать"
                loading={isGetEmployeesLoading}
                onSearch={handleChangePagination("name")}
                suffix={<SearchOutlined />}
              />
            </div>
          </div>
          <Table
            rowKey="id"
            columns={columns}
            dataSource={employees?.list}
            loading={isGetEmployeesLoading}
            onChange={handleTableChange}
            pagination={{
              current: (employees?.pagination.page ?? 0) + 1,
              pageSize: employees?.pagination.pageSize,
              total: employees?.pagination.total,
            }}
            scroll={{ x: 1500 }}
          />
          <div className={styles.docs}>
            <div className={styles.doc_card}>
              <div className={styles.doc_header}>
                <Title level={4}>Чеклисты</Title>
                <Link to={`${pages.docs}/create`}>
                  <Button type="primary" icon={<PlusOutlined />} />
                </Link>
              </div>
              {(isGetDocLoading || !!docs?.list?.length) && (
                <div className={styles.doc_wrapper}>
                  {docs?.list?.map((doc) => (
                    <EmployeeDoc key={doc.id} {...doc} />
                  ))}
                  {isGetDocLoading && <Skeleton.Input active block />}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  )
}
