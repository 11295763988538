import { Typography } from "antd"
import { HookAPI } from "antd/es/modal/useModal"

export const deleteEmployeeModal =
  (modal: HookAPI, onOk: (...args: any[]) => any) => () => {
    modal.confirm({
      centered: true,
      title: "Вы уверены что хотите удалить сотрудника?",
      okText: "Подтвердить",
      cancelText: "Отменить",
      content: (
        <Typography.Text>
          Восстановить данные будет невозможно, а сотрудник потеряет доступ
        </Typography.Text>
      ),
      onOk,
    })
  }
