import { UseBaseMutationResult, useMutation, useQueryClient } from "react-query"
import { IDoc } from "../../types"
import { useNavigate } from "react-router-dom"
import { pages } from "../../constants"
import { DOCS } from "../keys"
import { DocsService } from "../services/Docs.service"

export function useCreateDoc(): UseBaseMutationResult<IDoc, unknown, IDoc> {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  return useMutation(DocsService.createDocs, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(DOCS)

      navigate(pages.employees)
    },
  })
}
