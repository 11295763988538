import {
  IEmployee,
  IEmployeeListRes,
  IEmployeeListPagination,
} from "../../types"
import { request } from "../request"

export const EmployeeService = {
  async getEmployees(
    pagination: IEmployeeListPagination,
  ): Promise<IEmployeeListRes> {
    const { data } = await request.post("/users/list", pagination)

    return data
  },

  async getEmployeesAdmin(
    pagination: IEmployeeListPagination,
  ): Promise<IEmployeeListRes> {
    const { data } = await request.post("/users/list-admin", pagination)

    return data
  },

  async getEmployeeById(id?: string): Promise<IEmployee> {
    const { data } = await request.get(`/users/${id}`)

    return data
  },

  async createEmployee(payload: IEmployee): Promise<IEmployee> {
    const { data } = await request.post("/users/create", payload)

    return data
  },

  async updateEmployee(id: string, payload: IEmployee): Promise<IEmployee> {
    const { data } = await request.patch(`/users/${id}`, payload)

    return data
  },

  async deleteEmployee(id: string): Promise<string> {
    const { data } = await request.delete(`/users/${id}`)

    return data
  },
}
