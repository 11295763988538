import { UseBaseMutationResult, useMutation, useQueryClient } from "react-query"
import { BENEFITS } from "../keys"
import { BenefitsService } from "../services/Benefits.service"

export function useDeleteBenefits(
  id: string,
): UseBaseMutationResult<string, unknown, void> {
  const queryClient = useQueryClient()

  return useMutation(() => BenefitsService.deleteBenefits(id), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(BENEFITS)
    },
  })
}
