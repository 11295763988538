import { useLocation, useParams } from "react-router-dom"
import { CREATE_ID } from "../../constants"
import { Breadcrumb } from "antd"
import { generateBreadcrumbItems } from "./helper"
import { useMemo } from "react"

export const Breadcrumbs = () => {
  const { id } = useParams()
  const { pathname } = useLocation()

  const isCreate = id === CREATE_ID

  const breadcrumbItems = useMemo(
    () => generateBreadcrumbItems(isCreate, pathname),
    [isCreate, pathname],
  )
  return <Breadcrumb items={breadcrumbItems} />
}
