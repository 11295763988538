import Title from "antd/es/typography/Title"
import { MainLayout } from "../../components/MainLayout/MainLayout"
import Button from "antd/es/button"
import { PlusOutlined, SearchOutlined } from "@ant-design/icons"
import styles from "./MenuPage.module.css"
import Table, { ColumnsType, TablePaginationConfig } from "antd/es/table"
import { ICategory, IMenu, IMenuListPagination } from "../../types"
import {
  defaultPagination,
  ItemVisibilityStatuses,
  pages,
} from "../../constants"
import Link from "antd/es/typography/Link"
import { Link as RouterLink } from "react-router-dom"
import Typography from "antd/es/typography"
import { Badge } from "antd"
import Search from "antd/es/input/Search"
import { useGetMenu } from "../../queries/menu/useGetMenu"
import { useState } from "react"
import debounce from "lodash/debounce"
import { MenuAction } from "./MenuAction"

const columns: ColumnsType<IMenu> = [
  {
    title: "Название",
    dataIndex: "name",
    key: "name",
    width: 200,
    render: (value: string, item) =>
      ItemVisibilityStatuses.on_site.key === item.status ? (
        <Link href={`https://orengocake.ru/products/${(item.category as ICategory)?.name}/${item.id}`} target="_blank">
          {value}
        </Link>
      ) : (
        <Typography.Text>{value}</Typography.Text>
      ),
  },
  {
    title: "Описание",
    dataIndex: "description",
    key: "description",
    width: 200,
  },
  {
    title: "Цена",
    dataIndex: "cost",
    key: "cost",
    width: 80,
  },
  {
    title: "Вес",
    dataIndex: "weight",
    key: "weight",
    width: 80,
  },
  {
    title: "Ккал",
    dataIndex: "kcal",
    key: "kcal",
    width: 80,
  },
  {
    title: "Белки",
    dataIndex: "proteins",
    key: "proteins",
    width: 80,
  },
  {
    title: "Жиры",
    dataIndex: "fats",
    key: "fats",
    width: 80,
  },
  {
    title: "Углеводы",
    dataIndex: "carbohydrates",
    key: "carbohydrates",
    width: 80,
  },
  {
    title: "Статус",
    dataIndex: "status",
    key: "status",
    width: 120,
    render: (value: keyof typeof ItemVisibilityStatuses) => (
      <Badge
        status={
          ItemVisibilityStatuses.on_site.key === value ? "success" : "default"
        }
        text={ItemVisibilityStatuses[value].title}
      />
    ),
  },
  {
    title: "Действия",
    key: "id",
    dataIndex: "id",
    fixed: "right",
    width: 190,
    render: (value: string) => <MenuAction menuId={value} />,
  },
]

export const MenuPage = () => {
  const [pagination, setPagination] =
    useState<IMenuListPagination>(defaultPagination)

  const { data: menu, isLoading: isGetMenuLoading } = useGetMenu(pagination)

  const handleChangePagination = debounce((value: string) => {
    setPagination((prev) => {
      return { ...prev, search: value }
    })
  }, 200)

  const handleTableChange = ({ current }: TablePaginationConfig) => {
    setPagination((prev) => ({
      ...prev,
      page: (current ?? 1) - 1,
    }))
  }

  return (
    <MainLayout>
      <div className={styles.page}>
        <div className={styles.header}>
          <Title level={4}>Меню</Title>
          <RouterLink to={`${pages.menu}/create`}>
            <Button type="primary" icon={<PlusOutlined />}>
              Добавить позицию
            </Button>
          </RouterLink>
        </div>
        <div className={styles.content}>
          <Search
            className={styles.input}
            placeholder="Поиск по продуктам"
            enterButton="Искать"
            loading={isGetMenuLoading}
            suffix={<SearchOutlined />}
            onSearch={handleChangePagination}
          />
          <Table
            rowKey="id"
            columns={columns}
            dataSource={menu?.list}
            loading={isGetMenuLoading}
            onChange={handleTableChange}
            pagination={{
              current: (menu?.pagination.page ?? 0) + 1,
              pageSize: menu?.pagination.pageSize,
              total: menu?.pagination.total,
            }}
            scroll={{ x: 1500 }}
          />
        </div>
      </div>
    </MainLayout>
  )
}
