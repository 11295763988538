import { UseBaseMutationResult, useMutation, useQueryClient } from "react-query"
import { AuthService } from "../services/Auth.service"
import { USER } from "../keys"

export function useLogout(): UseBaseMutationResult<unknown> {
  const queryClient = useQueryClient()

  return useMutation(AuthService.logout, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(USER)
    },
  })
}
