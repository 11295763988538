import { IMenu, IMenuListPagination, IMenuListRes } from "../../types"
import { request } from "../request"

export const MenuService = {
  async getMenu(pagination: IMenuListPagination): Promise<IMenuListRes> {
    const { data } = await request.post("/menu/list", pagination)

    return data
  },

  async getMenuById(id?: string): Promise<IMenu> {
    const { data } = await request.get(`/menu/${id}`)

    return data
  },

  async createMenu(payload: IMenu): Promise<IMenu> {
    const { data } = await request.post("/menu/create", payload)

    return data
  },

  async updateMenu(
    id: string,
    payload: Omit<IMenu, "id" | "createdAt" | "updatedAt">,
  ): Promise<IMenu> {
    const { data } = await request.patch(`/menu/${id}`, payload)

    return data
  },

  async deleteMenu(id: string): Promise<string> {
    const { data } = await request.delete(`/menu/${id}`)

    return data
  },
}
