import {
  ICategory,
  ICategoryListPagination,
  ICategoryListRes,
} from "../../types"
import { request } from "../request"

export const CategoryService = {
  async getCategory(
    pagination: ICategoryListPagination,
  ): Promise<ICategoryListRes> {
    const { data } = await request.post("/categories/list", pagination)
    return data
  },

  async getCategoryById(id?: string): Promise<ICategory> {
    const { data } = await request.get(`/categories/${id}`)

    return data
  },

  async createCategory(payload: ICategory): Promise<ICategory> {
    const { data } = await request.post("/categories/create", payload)

    return data
  },

  async updateCategory(
    id: string,
    payload: Omit<ICategory, "id" | "createdAt" | "updatedAt">,
  ): Promise<ICategory> {
    const { data } = await request.patch(`/categories/${id}`, payload)

    return data
  },

  async deleteCategory(id: string): Promise<string> {
    const { data } = await request.delete(`/categories/${id}`)

    return data
  },
}
