import Title from "antd/es/typography/Title"
import styles from "./MenuFormPage.module.css"
import { MainLayout } from "../../components/MainLayout/MainLayout"
import { useParams } from "react-router-dom"
import { CREATE_ID, defaultPagination, menuStatusesForm } from "../../constants"
import { IMenu } from "../../types"
import { FormCard } from "../../components/FormCard/FormCard"
import { Button, Checkbox, Form, Input, InputNumber, Radio, Select } from "antd"
import { FormButtons } from "../../components/FormCard/FormButtons"
import { Breadcrumbs } from "../../components/Breadcrumbs/Breadcrumbs"
import { useGetMenuById } from "../../queries/menu/useGetMenuById"
import { useCreateMenu } from "../../queries/menu/useCreateMenu"
import { useUpdateMenu } from "../../queries/menu/useUpdateMenu"
import { UploadFile } from "antd/es/upload"
import { UploadFileInput } from "../../components/UploadFile/UploadFile"
import { useGetCategory } from "../../queries/category/useGetCategory"

export const MenuFormPage = () => {
  const { id } = useParams()
  const isCreate = id === CREATE_ID

  const { data, isLoading: isGetMenuByIdLoading } = useGetMenuById(id)
  const { data: categories, isLoading: isGetCategoriesListLoading } =
    useGetCategory({
      ...defaultPagination,
      pageSize: 100,
    })

  const { mutate: saveMenu, isLoading: isCreateMenuLoading } = useCreateMenu()
  const { mutate: updateMenu, isLoading: isUpdateMenuLoading } = useUpdateMenu(
    id as string,
  )

  const onFinish = (values: IMenu) => {
    const images: UploadFile[] = values?.images?.map(
      (image) => image?.response ?? image,
    )

    if (isCreate) {
      saveMenu({ ...values, images })
    } else {
      updateMenu({ ...values, images })
    }
  }

  const filterOption = (
    input: string,
    option?: { label: string; value: string },
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())

  return (
    <MainLayout>
      <div className={styles.page}>
        <div className={styles.header}>
          <Breadcrumbs />
          <Title level={4}>
            {isCreate ? "Добавление позиции" : "Редактирование позиции"}
          </Title>
        </div>
        <div className={styles.content}>
          <FormCard
            key={data?.name}
            disabled={
              isCreateMenuLoading || isUpdateMenuLoading || isGetMenuByIdLoading
            }
            name="employee"
            onFinish={onFinish}
            initialValues={data}
          >
            <Form.Item
              name={"name"}
              label="Название"
              rules={[{ required: true }]}
            >
              <Input placeholder="Введите название" />
            </Form.Item>
            <Form.Item
              name={"description"}
              label="Описание"
              rules={[{ required: true }]}
            >
              <Input.TextArea placeholder="Введите описание" />
            </Form.Item>
            <Form.Item
              name={"category"}
              label="Категория"
              rules={[{ required: true }]}
            >
              <Select
                showSearch
                placeholder="Выбирите категорию"
                optionFilterProp="children"
                disabled={isGetCategoriesListLoading}
                filterOption={filterOption}
                options={categories?.list.map((category) => ({
                  value: category.id,
                  label: category.displayName,
                }))}
              />
            </Form.Item>
            <UploadFileInput
              accept=".jpg,.jpeg,.png,.heic"
              name={"images"}
              label="Фото продукта (от 1 до 3)"
              text="Нажмите чтобы загрузить"
              maxCount={3}
              type="image"
            />
            <Form.Item name={"cost"} label="Цена" rules={[{ required: true }]}>
              <InputNumber
                addonBefore="₽"
                placeholder="Только число в ₽ без пробелов"
                style={{ width: "100%" }}
              />
            </Form.Item>
            <Form.Item name={"weight"} label="Вес" rules={[{ required: true }]}>
              <InputNumber
                placeholder="Только число в граммах без пробелов"
                style={{ width: "100%" }}
              />
            </Form.Item>
            <Form.Item name={"kcal"} label="Ккал" rules={[{ required: true }]}>
              <InputNumber
                placeholder="Только число без пробелов"
                style={{ width: "100%" }}
              />
            </Form.Item>
            <Form.Item
              name={"proteins"}
              label="Белки"
              rules={[{ required: false }]}
            >
              <InputNumber
                placeholder="Только число без пробелов"
                style={{ width: "100%" }}
              />
            </Form.Item>
            <Form.Item name={"fats"} label="Жиры" rules={[{ required: false }]}>
              <InputNumber
                placeholder="Только число без пробелов"
                style={{ width: "100%" }}
              />
            </Form.Item>
            <Form.Item
              name={"carbohydrates"}
              label="Углеводы"
              rules={[{ required: false }]}
            >
              <InputNumber
                placeholder="Только число без пробелов"
                style={{ width: "100%" }}
              />
            </Form.Item>
            <Form.Item
              name={"status"}
              label="Статус"
              rules={[{ required: true }]}
            >
              <Radio.Group>
                {menuStatusesForm.map((menuStatus) => (
                  <Radio key={menuStatus.key} value={menuStatus.key}>
                    {menuStatus.title}
                  </Radio>
                ))}
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name={"isPn"}
              valuePropName="checked"
              label="Правильное питание"
            >
              <Checkbox defaultChecked={false} />
            </Form.Item>
            <Form.Item name={"isHit"} valuePropName="checked" label="Хит">
              <Checkbox defaultChecked={false} />
            </Form.Item>
            <FormButtons>
              <Button type="primary" htmlType="submit">
                {isCreate ? "Добавить" : "Сохранить"}
              </Button>
              <Button htmlType="reset">Сбросить</Button>
            </FormButtons>
          </FormCard>
        </div>
      </div>
    </MainLayout>
  )
}
