import { FC } from "react"
import { IEmployee } from "../../types"
import { UserOutlined } from "@ant-design/icons"
import { Avatar, Typography } from "antd"
import styles from "./ProfileCard.module.css"
import Title from "antd/es/typography/Title"
import { positionTargets } from "../../constants"
import dayjs from "dayjs"

interface IProfileCardProps {
  profile?: Omit<IEmployee, "id">
  hideInfo?: boolean
}

export const ProfileCard: FC<IProfileCardProps> = ({ profile, hideInfo }) => {
  const position = profile?.position as keyof typeof positionTargets

  return (
    <div className={styles.card}>
      <Avatar
        src={profile?.images?.[0]?.url}
        size={160}
        icon={<UserOutlined />}
      />
      <div>
        <Title level={4}>{profile?.name}</Title>
        <Typography.Text type="secondary">
          {`${positionTargets[position]?.title} (${profile?.block})`}
        </Typography.Text>
      </div>
      <div className={styles.card_info}>
        <div className={styles.card_item}>
          <div className={styles.card_item_title}>
            <Typography.Text>Дата рождения:</Typography.Text>
          </div>
          <div className={styles.card_item_value}>
            <Typography.Text>
              {dayjs(profile?.dateOfBirth).format("DD.MM.YYYY")}
            </Typography.Text>
          </div>
        </div>
        <div className={styles.card_item}>
          <div className={styles.card_item_title}>
            <Typography.Text>Телефон:</Typography.Text>
          </div>
          <div className={styles.card_item_value}>
            <Typography.Text>{profile?.phone}</Typography.Text>
          </div>
        </div>
        {!hideInfo && (
          <>
            <div className={styles.card_item}>
              <div className={styles.card_item_title}>
                <Typography.Text>Логин:</Typography.Text>
              </div>
              <div className={styles.card_item_value}>
                <Typography.Text>{profile?.login}</Typography.Text>
              </div>
            </div>
            <div className={styles.card_item}>
              <div className={styles.card_item_title}>
                <Typography.Text>Пароль:</Typography.Text>
              </div>
              <div className={styles.card_item_value}>
                <Typography.Text>{profile?.password}</Typography.Text>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}
