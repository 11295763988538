import Title from "antd/es/typography/Title"
import styles from "./ProfilePage.module.css"
import { Button, Radio, RadioChangeEvent, Skeleton } from "antd"
import { PlusOutlined, SearchOutlined } from "@ant-design/icons"
import { useEffect, useState } from "react"
import Search from "antd/es/input/Search"
import { defaultPagination, newsTargets, pages } from "../../constants"
import { MainLayout } from "../../components/MainLayout/MainLayout"
import { INewsListPagination } from "../../types"
import { useGetNews } from "../../queries/news/useGetNews"
import { Link } from "react-router-dom"
import debounce from "lodash/debounce"
import { useInView } from "react-intersection-observer"
import { NewsCard } from "../NewsPage/NewsCard"
import { ProfileCard } from "../../components/ProfileCard/ProfileCard"
import { useGetProfile } from "../../queries/auth/useGetProfile"
import { BenefitsCard } from "../../components/BenefitsCard/BenefitsCard"

export const ProfilePage = () => {
  const { ref, inView } = useInView()

  const { data: profile, isLoading: isGetProfileLoading } = useGetProfile()
  const {
    data: news,
    isLoading: isGetNewsLoading,
    hasNextPage,
    fetchNextPage,
  } = useGetNews(defaultPagination)

  useEffect(() => {
    if (inView && !isGetNewsLoading) {
      fetchNextPage()
    }
  }, [fetchNextPage, inView, isGetNewsLoading])

  return (
    <MainLayout>
      <div className={styles.page}>
        <div className={styles.content}>
          <div className={styles.info}>
            <ProfileCard key={profile?.id} hideInfo profile={profile} />
            <BenefitsCard />
          </div>
          <div className={styles.list}>
            {news?.pages.map((page) =>
              page?.list?.map((newsItem) => (
                <NewsCard hideControl key={newsItem.id} {...newsItem} />
              )),
            )}
            {hasNextPage && (
              <div ref={ref} className={styles.card}>
                <Skeleton active />
              </div>
            )}
          </div>
        </div>
      </div>
    </MainLayout>
  )
}
