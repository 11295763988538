import { QueryObserverResult, useQuery } from "react-query"
import { AuthService } from "../services/Auth.service"
import { USER } from "../keys"
import { IEmployee } from "../../types"

export function useGetProfile(): QueryObserverResult<IEmployee> {
  return useQuery([USER], AuthService.getGetProfile, {
    refetchOnMount: false,
  })
}
