import { UseBaseMutationResult, useMutation, useQueryClient } from "react-query"
import { IMenu } from "../../types"
import { useNavigate } from "react-router-dom"
import { pages } from "../../constants"
import { MenuService } from "../services/Menu.service"
import { MENU } from "../keys"

export function useCreateMenu(): UseBaseMutationResult<IMenu, unknown, IMenu> {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  return useMutation(MenuService.createMenu, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(MENU)

      navigate(pages.menu)
    },
  })
}
