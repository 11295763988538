import Title from "antd/es/typography/Title"
import { MainLayout } from "../../components/MainLayout/MainLayout"
import { SearchOutlined } from "@ant-design/icons"
import styles from "./MenuListPage.module.css"
import Table, { ColumnsType, TablePaginationConfig } from "antd/es/table"
import { ICategory, IMenu, IMenuListPagination } from "../../types"
import { defaultPagination, ItemVisibilityStatuses } from "../../constants"
import Link from "antd/es/typography/Link"
import Typography from "antd/es/typography"
import Search from "antd/es/input/Search"
import { useGetMenu } from "../../queries/menu/useGetMenu"
import { useState } from "react"
import debounce from "lodash/debounce"

const columns: ColumnsType<IMenu> = [
  {
    title: "Название",
    dataIndex: "name",
    key: "name",
    width: 200,
    render: (value: string, item) =>
      ItemVisibilityStatuses.on_site.key === item.status ? (
        <Link href={`https://orengocake.ru/products/${(item.category as ICategory)?.name}/${item.id}`} target="_blank">
          {value}
        </Link>
      ) : (
        <Typography.Text>{value}</Typography.Text>
      ),
  },
  {
    title: "Правильное питание",
    dataIndex: "isPn",
    key: "isPn",
    width: 80,
  },
  {
    title: "Описание",
    dataIndex: "description",
    key: "description",
    width: 200,
  },
  {
    title: "Цена",
    dataIndex: "cost",
    key: "cost",
    width: 80,
  },
  {
    title: "Вес",
    dataIndex: "weight",
    key: "weight",
    width: 80,
  },
  {
    title: "Ккал",
    dataIndex: "kcal",
    key: "kcal",
    width: 80,
  },
]

export const MenuListPage = () => {
  const [pagination, setPagination] =
    useState<IMenuListPagination>(defaultPagination)

  const { data: menu, isLoading: isGetMenuLoading } = useGetMenu(pagination)

  const handleChangePagination = debounce((value: string) => {
    setPagination((prev) => {
      return { ...prev, search: value }
    })
  }, 200)

  const handleTableChange = ({ current }: TablePaginationConfig) => {
    setPagination((prev) => ({
      ...prev,
      page: (current ?? 1) - 1,
    }))
  }

  return (
    <MainLayout>
      <div className={styles.page}>
        <div className={styles.header}>
          <Title level={4}>Меню</Title>
          <Search
            style={{ width: 300 }}
            name="search"
            placeholder="Поиск по продуктам"
            enterButton="Искать"
            loading={isGetMenuLoading}
            onSearch={handleChangePagination}
            suffix={<SearchOutlined />}
          />
        </div>
        <div className={styles.content}>
          <Table
            rowKey="id"
            columns={columns}
            dataSource={menu?.list}
            loading={isGetMenuLoading}
            onChange={handleTableChange}
            pagination={{
              current: (menu?.pagination.page ?? 0) + 1,
              pageSize: menu?.pagination.pageSize,
              total: menu?.pagination.total,
            }}
          />
        </div>
      </div>
    </MainLayout>
  )
}
