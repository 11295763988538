import { QueryObserverResult, useQuery } from "react-query"
import { ICategory, IMenu } from "../../types"
import { MENU } from "../keys"
import { MenuService } from "../services/Menu.service"
import { CREATE_ID } from "../../constants"

export function useGetMenuById(id?: string): QueryObserverResult<IMenu> {
  return useQuery([MENU, id], () => MenuService.getMenuById(id), {
    enabled: !!(id && id !== CREATE_ID),
    select: (data) => {
      return {
        ...data,
        category: (data?.category as ICategory)?.id
      }
    }
  })
}
