import Title from "antd/es/typography/Title"
import styles from "./NewsFormPage.module.css"
import { MainLayout } from "../../components/MainLayout/MainLayout"
import { useParams } from "react-router-dom"
import { CREATE_ID, newsTargets } from "../../constants"
import { useGetNewsById } from "../../queries/news/useGetNewsById"
import { useCreateNews } from "../../queries/news/useCreateNews"
import { INews } from "../../types"
import { FormCard } from "../../components/FormCard/FormCard"
import { Button, Checkbox, Form, Input, Radio, UploadFile } from "antd"
import { FormButtons } from "../../components/FormCard/FormButtons"
import { useUpdateNews } from "../../queries/news/useUpdateNews"
import { Breadcrumbs } from "../../components/Breadcrumbs/Breadcrumbs"
import { UploadFileInput } from "../../components/UploadFile/UploadFile"

export const NewsFormPage = () => {
  const { id } = useParams()

  const isCreate = id === CREATE_ID

  const { data, isLoading: isGetNewsByIdLoading } = useGetNewsById(id)

  const { mutate: saveNews, isLoading: isCreateNewsLoading } = useCreateNews()
  const { mutate: updateNews, isLoading: isUpdateNewsLoading } = useUpdateNews(
    id as string,
  )

  const onFinish = (values: INews) => {
    const images: UploadFile[] = values?.images?.map(
      (image) => image?.response ?? image,
    )

    const videos: UploadFile[] = values?.videos?.map(
      (video) => video?.response ?? video,
    )

    if (isCreate) {
      saveNews({ ...values, images, videos })
    } else {
      updateNews({ ...values, images, videos })
    }
  }

  return (
    <MainLayout>
      <div className={styles.page}>
        <div className={styles.header}>
          <Breadcrumbs />
          <Title level={4}>
            {isCreate ? "Добавление новости" : "Редактирование новости"}
          </Title>
        </div>
        <div className={styles.content}>
          <FormCard
            key={data?.title}
            disabled={
              isCreateNewsLoading || isGetNewsByIdLoading || isUpdateNewsLoading
            }
            name="news"
            onFinish={onFinish}
            initialValues={{ target: newsTargets.all.key, ...data }}
          >
            <Form.Item
              name={"title"}
              label="Заголовок новости"
              rules={[{ required: true }]}
            >
              <Input placeholder="Заголовок" />
            </Form.Item>
            <Form.Item
              name={"text"}
              label="Текст новости"
              rules={[{ required: true }]}
            >
              <Input.TextArea placeholder="Новость" />
            </Form.Item>
            <UploadFileInput
              accept=".jpg,.jpeg,.png,.heic"
              name={"images"}
              label="Загрузите до 3х фотографий"
              text="Нажмите чтобы загрузить"
              maxCount={3}
              type="image"
            />
            <Form.Item
              name={"link"}
              label="Ссылка на видео"
              initialValue={isCreate ? "" : undefined}
              required={false}
            >
              <Input placeholder="https://example.com" />
            </Form.Item>
            <UploadFileInput
              accept="video/*"
              name={"videos"}
              label="Видео"
              text="Загрузить"
              maxCount={1}
              type="video"
            />
            <Form.Item name={"target"} label="Кто будет видеть">
              <Radio.Group>
                {Object.values(newsTargets).map(({ key, title }) => (
                  <Radio key={key} value={key}>
                    {title}
                  </Radio>
                ))}
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name={"isVacancy"}
              valuePropName="checked"
              label="Вакансия"
            >
              <Checkbox />
            </Form.Item>
            <FormButtons>
              <Button type="primary" htmlType="submit">
                {isCreate ? "Опубликовать" : "Сохранить"}
              </Button>
              <Button htmlType="reset">Сбросить</Button>
            </FormButtons>
          </FormCard>
        </div>
      </div>
    </MainLayout>
  )
}
