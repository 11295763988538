import { QueryObserverResult, useQuery } from "react-query"
import { ICategoryListPagination, ICategoryListRes } from "../../types"
import { CATEGORY } from "../keys"
import { CategoryService } from "../services/Category.service"

export function useGetCategory(
  pagination: ICategoryListPagination,
  enabled = true,
): QueryObserverResult<ICategoryListRes> {
  return useQuery(
    [CATEGORY, pagination.page, pagination.pageSize, pagination.search],
    () => CategoryService.getCategory(pagination),
    { enabled },
  )
}
