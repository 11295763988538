import { QueryObserverResult, useQuery } from "react-query"
import { IBenefitsListPagination, IBenefitsListRes } from "../../types"
import { BENEFITS } from "../keys"
import { BenefitsService } from "../services/Benefits.service"

export function useGetBenefits(
  pagination: IBenefitsListPagination,
  enabled = true,
): QueryObserverResult<IBenefitsListRes> {
  return useQuery(
    [
      BENEFITS,
      pagination.page,
      pagination.pageSize,
      pagination.search,
      pagination.type,
    ],
    () => BenefitsService.getBenefits(pagination),
    { enabled },
  )
}
