import {
  IBenefit,
  IBenefitsListRes,
  IBenefitsListPagination,
  ICreateBenefitReq,
} from "../../types"
import { request } from "../request"

export const BenefitsService = {
  async getBenefits(
    pagination: IBenefitsListPagination,
  ): Promise<IBenefitsListRes> {
    const { data } = await request.post("/benefits/list", pagination)

    return data
  },

  async getBenefitsById(id?: string): Promise<IBenefit> {
    const { data } = await request.get(`/benefits/${id}`)

    return data
  },

  async createBenefits(payload: ICreateBenefitReq): Promise<IBenefit[]> {
    const { data } = await request.post("/benefits/create", payload)

    return data
  },

  async updateBenefits(
    id: string,
    payload: Omit<IBenefit, "id">,
  ): Promise<IBenefit> {
    const { data } = await request.patch(`/benefits/${id}`, payload)

    return data
  },

  async deleteBenefits(id: string): Promise<string> {
    const { data } = await request.delete(`/benefits/${id}`)

    return data
  },
}
