import { Button, Modal } from "antd"
import { FC } from "react"
import styles from "./EmployeesPage.module.css"
import { DeleteOutlined, EditOutlined } from "@ant-design/icons"
import { Link } from "react-router-dom"
import { pages } from "../../constants"
import { useDeleteEmployee } from "../../queries/employee/useDeleteEmployee"
import { deleteEmployeeModal } from "../../components/Modals/deleteEmployeeModal"

interface IEmployeesActionProps {
  employeeId: string
}

export const EmployeesAction: FC<IEmployeesActionProps> = ({ employeeId }) => {
  const [modal, contextHolder] = Modal.useModal()
  const { mutateAsync: deleteEmployee, isLoading } =
    useDeleteEmployee(employeeId)

  return (
    <div className={styles.actions}>
      <Link to={`${pages.employees}/${employeeId}`}>
        <Button type="primary" icon={<EditOutlined />} />
      </Link>
      <Button
        type="primary"
        danger
        onClick={deleteEmployeeModal(modal, deleteEmployee)}
        icon={<DeleteOutlined />}
        loading={isLoading}
      />
      {contextHolder}
    </div>
  )
}
