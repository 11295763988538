import { QueryObserverResult, useQuery } from "react-query"
import { IBenefit } from "../../types"
import { BENEFITS } from "../keys"
import { CREATE_ID } from "../../constants"
import { BenefitsService } from "../services/Benefits.service"

export function useGetBenefitsById(id?: string): QueryObserverResult<IBenefit> {
  return useQuery([BENEFITS, id], () => BenefitsService.getBenefitsById(id), {
    enabled: !!(id && id !== CREATE_ID),
  })
}
