import { UseBaseMutationResult, useMutation, useQueryClient } from "react-query"
import { ICategory } from "../../types"
import { useNavigate } from "react-router-dom"
import { pages } from "../../constants"
import { CategoryService } from "../services/Category.service"
import { CATEGORY } from "../keys"

export function useCreateCategory(): UseBaseMutationResult<
  ICategory,
  unknown,
  ICategory
> {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  return useMutation(CategoryService.createCategory, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(CATEGORY)

      navigate(pages.categoryList)
    },
  })
}
