import { HookAPI } from "antd/es/modal/useModal"
import { IEmployee } from "../../types"
import { ProfileCard } from "../ProfileCard/ProfileCard"

export const showEmployeeModal =
  (modal: HookAPI, employee: IEmployee, hideInfo = true) =>
  () => {
    modal.info({
      closable: true,
      centered: true,
      content: <ProfileCard hideInfo={hideInfo} profile={employee} />,
      footer: false,
      className: "custom_modal",
    })
  }
