import { INews, INewsListPagination, INewsListRes } from "../../types"
import { request } from "../request"

export const NewsService = {
  async getNews(pagination: INewsListPagination): Promise<INewsListRes> {
    const { data } = await request.post("/news/list", pagination)

    return data
  },

  async getNewsById(id?: string): Promise<INews> {
    const { data } = await request.get(`/news/${id}`)

    return data
  },

  async createNews(payload: INews): Promise<INews> {
    const { data } = await request.post("/news/create", payload)

    return data
  },

  async updateNews(id: string, payload: INews): Promise<INews> {
    const { data } = await request.patch(`/news/${id}`, payload)

    return data
  },

  async deleteNews(id: string): Promise<string> {
    const { data } = await request.delete(`/news/${id}`)

    return data
  },
}
