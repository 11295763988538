import { AxiosError } from "axios"
import { IEmployee, ILoginReq } from "../../types"
import { request } from "../request"

export const AuthService = {
  async getGetProfile(): Promise<IEmployee> {
    const { data } = await request.get("/auth/profile")

    return data
  },

  async login(payload: ILoginReq): Promise<IEmployee> {
    try {
      const { data } = await request.post("/auth/login", payload)

      return data
    } catch (e) {
      if (!(e instanceof AxiosError)) throw e

      let message = "Ошибка! Попробуйте ещё раз!"

      switch (e.code) {
        case "ERR_BAD_REQUEST":
          message = "Неверный логин или пароль!"
          break
      }

      throw new AxiosError(message)
    }
  },

  logout(): Promise<unknown> {
    return request.get("/auth/logout")
  },
}
