import { FC, ReactElement } from "react"
import styles from "./FormCard.module.css"
import { Form, FormProps } from "antd"
import { validateMessages } from "../../constants"

interface IFormCardProps extends FormProps {
  children: ReactElement[]
}

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
}

export const FormCard: FC<IFormCardProps> = ({ children, ...formProps }) => {
  return (
    <div className={styles.card}>
      <Form
        validateMessages={validateMessages}
        {...formItemLayout}
        {...formProps}
      >
        {children}
      </Form>
    </div>
  )
}
