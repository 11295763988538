import { IDoc, IDocsListRes } from "../../types"
import { request } from "../request"

export const DocsService = {
  async getDocs(): Promise<IDocsListRes> {
    const { data } = await request.get("/docs/list")

    return data
  },

  async getDocsById(id?: string): Promise<IDoc> {
    const { data } = await request.get(`/docs/${id}`)

    return data
  },

  async createDocs(payload: IDoc): Promise<IDoc> {
    const { data } = await request.post("/docs/create", payload)

    return data
  },

  async updateDocs(id: string, payload: IDoc): Promise<IDoc> {
    const { data } = await request.patch(`/docs/${id}`, payload)

    return data
  },

  async deleteDocs(id: string): Promise<string> {
    const { data } = await request.delete(`/docs/${id}`)

    return data
  },
}
