import { ConfigProvider } from "antd"
import { RouterProvider } from "react-router-dom"
import { router } from "./pages"
import { App } from "antd"
import ru_RU from "antd/lib/locale/ru_RU"
import dayjs from "dayjs"
import "dayjs/locale/ru"

dayjs.locale("ru")

const themeConfig = {
  components: {
    Button: {
      borderRadius: 2,
    },
    Input: {
      borderRadius: 2,
    },
  },
  token: {
    fontFamily:
      '"Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",sans-serif',
  },
}

export const MyApp = () => {
  return (
    <ConfigProvider theme={themeConfig} locale={ru_RU}>
      <App>
        <RouterProvider router={router} />
      </App>
    </ConfigProvider>
  )
}
