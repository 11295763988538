import { UseBaseMutationResult, useMutation, useQueryClient } from "react-query"
import { IBenefit } from "../../types"
import { useNavigate } from "react-router-dom"
import { pages } from "../../constants"
import { BENEFITS } from "../keys"
import { BenefitsService } from "../services/Benefits.service"

export function useUpdateBenefits(
  id: string,
): UseBaseMutationResult<IBenefit, unknown, Omit<IBenefit, "id">> {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  return useMutation((payload) => BenefitsService.updateBenefits(id, payload), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(BENEFITS)

      navigate(pages.benefits)
    },
  })
}
