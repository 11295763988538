import { UseBaseMutationResult, useMutation, useQueryClient } from "react-query"
import { IEmployee } from "../../types"
import { useNavigate } from "react-router-dom"
import { pages } from "../../constants"
import { EMPLOYEES } from "../keys"
import { EmployeeService } from "../services/Employee.service"

export function useUpdateEmployee(
  id: string,
): UseBaseMutationResult<IEmployee, unknown, IEmployee> {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  return useMutation((payload) => EmployeeService.updateEmployee(id, payload), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(EMPLOYEES)

      navigate(pages.employees)
    },
  })
}
