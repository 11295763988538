import Title from "antd/es/typography/Title"
import styles from "./EmployeesFormPage.module.css"
import { MainLayout } from "../../components/MainLayout/MainLayout"
import { useParams } from "react-router-dom"
import { CREATE_ID, blocks, positionTargets } from "../../constants"
import { IEmployee } from "../../types"
import { FormCard } from "../../components/FormCard/FormCard"
import {
  Button,
  DatePicker,
  Form,
  Input,
  Radio,
  Select,
  UploadFile,
} from "antd"
import { FormButtons } from "../../components/FormCard/FormButtons"
import { Breadcrumbs } from "../../components/Breadcrumbs/Breadcrumbs"
import { useGetEmployeeById } from "../../queries/employee/useGetEmployeeById"
import { useCreateEmployee } from "../../queries/employee/useCreateEmployee"
import { useUpdateEmployee } from "../../queries/employee/useUpdateEmployee"
import dayjs from "dayjs"
import { RangePickerProps } from "antd/es/date-picker"
import { UploadFileInput } from "../../components/UploadFile/UploadFile"

const disabledDate: RangePickerProps["disabledDate"] = (current) => {
  return current && current > dayjs().endOf("day")
}

export const EmployeesFormPage = () => {
  const { id } = useParams()

  const isCreate = id === CREATE_ID

  const { data, isLoading: isGetEmployeeByIdLoading } = useGetEmployeeById(id)

  const { mutate: saveEmployee, isLoading: isCreateEmployeeLoading } =
    useCreateEmployee()
  const { mutate: updateEmployee, isLoading: isUpdateEmployeeLoading } =
    useUpdateEmployee(id as string)

  const onFinish = (values: IEmployee) => {
    const images: UploadFile[] = values?.images?.map(
      (image) => image?.response ?? image,
    )

    if (isCreate) {
      saveEmployee({
        ...values,
        images,
        dateOfBirth: new Date(values.dateOfBirth as string).toISOString(),
      })
    } else {
      updateEmployee({
        ...values,
        images,
        dateOfBirth: new Date(values.dateOfBirth as string).toISOString(),
      })
    }
  }

  return (
    <MainLayout>
      <div className={styles.page}>
        <div className={styles.header}>
          <Breadcrumbs />
          <Title level={4}>
            {isCreate
              ? "Добавить нового сотрудника"
              : "Изменить данные сотрудника"}
          </Title>
        </div>
        <div className={styles.content}>
          <FormCard
            key={data?.name}
            disabled={
              isCreateEmployeeLoading ||
              isUpdateEmployeeLoading ||
              isGetEmployeeByIdLoading
            }
            name="employee"
            onFinish={onFinish}
            initialValues={{ position: positionTargets.bartender.key, ...data }}
          >
            <Form.Item
              name={"name"}
              label="ФИО сотрудника"
              rules={[{ required: true }]}
            >
              <Input placeholder="ФИО" />
            </Form.Item>
            <UploadFileInput
              accept=".jpg,.jpeg,.png,.heic"
              name={"images"}
              label="Фото сотрудника (от 1 до 3)"
              text="Нажмите чтобы загрузить"
              maxCount={3}
              type="image"
            />
            <Form.Item name={"position"} label="Позиция сотрудника">
              <Radio.Group>
                {Object.values(positionTargets).map(({ key, title }) => (
                  <Radio key={key} value={key}>
                    {title}
                  </Radio>
                ))}
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name={"dateOfBirth"}
              label="Дата рождения"
              rules={[{ required: true }]}
            >
              <DatePicker
                disabledDate={disabledDate}
                placeholder="Выберите дату"
                format="DD.MM.YYYY"
              />
            </Form.Item>
            <Form.Item
              name={"phone"}
              label="Телефон сотрудника"
              rules={[{ required: true }]}
            >
              <Input placeholder="+7" />
            </Form.Item>
            <Form.Item
              name={"login"}
              label="Логин"
              rules={[{ required: true }]}
            >
              <Input placeholder="********" />
            </Form.Item>
            <Form.Item
              name={"password"}
              label="Пароль"
              rules={[{ required: true }]}
            >
              <Input.Password placeholder="********" />
            </Form.Item>
            <Form.Item
              name={"block"}
              label="Подразделение"
              rules={[{ required: true }]}
            >
              <Select options={blocks} />
            </Form.Item>
            <FormButtons>
              <Button type="primary" htmlType="submit">
                {isCreate ? "Добавить" : "Сохранить"}
              </Button>
              <Button htmlType="reset">Сбросить</Button>
            </FormButtons>
          </FormCard>
        </div>
      </div>
    </MainLayout>
  )
}
