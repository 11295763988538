import { Table, TablePaginationConfig, Typography } from "antd"
import styles from "./BenefitsCard.module.css"
import { benefitTypes, defaultPagination } from "../../constants"
import { useGetBenefits } from "../../queries/benefits/useGetBenefits"
import { IBenefit, IBenefitsListPagination } from "../../types"
import { FC, useEffect, useState } from "react"
import dayjs from "dayjs"
import { ColumnsType } from "antd/es/table"
import clsx from "clsx"

const columns: ColumnsType<IBenefit> = [
  {
    title: "Дата",
    dataIndex: "date",
    key: "date",
    width: "70px",
    render: (value: string) => dayjs(value).format("DD.MM.YY"),
  },
  {
    title: "Тип",
    dataIndex: "type",
    key: "type",
    width: "70px",
    render: (value: keyof typeof benefitTypes) => (
      <div className={styles.row}>
        <div className={clsx(styles.badge, styles[value])} />
        {benefitTypes[value].title}
      </div>
    ),
  },
  {
    title: "Описание (повод)",
    dataIndex: "description",
    key: "description",
    width: "100%",
    align: "end",
    render: (value = "-") => (
      <Typography.Text type="secondary">{value}</Typography.Text>
    ),
  },
  {
    title: "Сумма",
    dataIndex: "amount",
    key: "amount",
    render: (value, item) =>
      `${benefitTypes.bonus.key === item?.type ? "+" : "-"}${value}`,
  },
]

interface IBenefitsTableProps {
  type?: keyof typeof benefitTypes
}

export const BenefitsTable: FC<IBenefitsTableProps> = ({ type }) => {
  const [pagination, setPagination] = useState<IBenefitsListPagination>({
    ...defaultPagination,
    pageSize: 5,
  })

  const { data: benefits, isLoading: isGetBenefitsLoading } =
    useGetBenefits(pagination)

  const handleTableChange = ({ current }: TablePaginationConfig) => {
    setPagination((prev) => ({
      ...prev,
      page: (current ?? 1) - 1,
    }))
  }

  useEffect(() => {
    if (!!type) {
      setPagination((prev) => ({
        ...prev,
        type,
      }))
    }
  }, [type])

  return (
    <Table
      rowKey="id"
      size="small"
      showHeader={false}
      columns={columns}
      loading={isGetBenefitsLoading}
      dataSource={benefits?.list}
      onChange={handleTableChange}
      pagination={{
        current: (benefits?.pagination.page ?? 0) + 1,
        pageSize: benefits?.pagination.pageSize,
        total: benefits?.pagination.total,
      }}
    />
  )
}
