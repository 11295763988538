import { redirect } from "react-router-dom"
import jwtDecode from "jwt-decode"
import { ADMIN_POSITION, pages } from "../constants"
import { IEmployee } from "../types"

export const adminProtection = () => {
  const token = localStorage.getItem("accessToken") as string
  const decodedData = jwtDecode<IEmployee>(token)

  if (!decodedData?.id) {
    return redirect(pages.default)
  }

  if (decodedData?.position !== ADMIN_POSITION) {
    return redirect(pages.profile)
  }

  return { user: decodedData?.id }
}
