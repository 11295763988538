import { QueryObserverResult, useQuery } from "react-query"
import { IDoc } from "../../types"
import { DOCS } from "../keys"
import { CREATE_ID } from "../../constants"
import { DocsService } from "../services/Docs.service"

export function useGetDocById(id?: string): QueryObserverResult<IDoc> {
  return useQuery([DOCS, id], () => DocsService.getDocsById(id), {
    enabled: !!(id && id !== CREATE_ID),
  })
}
