import { FC } from "react"
import styles from "./EmployeesPage.module.css"
import { Button, Modal } from "antd"
import { DeleteOutlined, EditOutlined } from "@ant-design/icons"
import { IDoc } from "../../types"
import { Link as RouterLink } from "react-router-dom"
import Link from "antd/es/typography/Link"
import { pages } from "../../constants"
import { useDeleteDoc } from "../../queries/docs/useDeleteDoc"
import { deleteDocsModal } from "../../components/Modals/deleteDocsModal"

export const EmployeeDoc: FC<IDoc> = ({ id, name, link }) => {
  const [modal, contextHolder] = Modal.useModal()

  const { mutateAsync: deleteDoc } = useDeleteDoc(id)

  return (
    <div key={id} className={styles.doc_item}>
      <Link href={link} target="_blank">
        {name}
      </Link>
      <div className={styles.doc_item_actions}>
        <RouterLink to={`${pages.docs}/${id}`}>
          <Button icon={<EditOutlined />} />
        </RouterLink>
        <Button
          icon={<DeleteOutlined />}
          onClick={deleteDocsModal(modal, deleteDoc)}
        />
      </div>
      {contextHolder}
    </div>
  )
}
