import Title from "antd/es/typography/Title"
import styles from "./CheckListPage.module.css"
import { Skeleton } from "antd"
import { MainLayout } from "../../components/MainLayout/MainLayout"
import { useGetDoc } from "../../queries/docs/useGetDocs"
import Link from "antd/es/typography/Link"

export const CheckListPage = () => {
  const { data: docs, isLoading: isGetDocLoading } = useGetDoc()

  return (
    <MainLayout>
      <div className={styles.page}>
        <div className={styles.header}>
          <Title level={4}>
            Информация о том, что такое и зачем нужны чеклисты
          </Title>
        </div>
        <div className={styles.content}>
          <div className={styles.docs}>
            <div className={styles.doc_card}>
              <div className={styles.doc_header}>
                <Title level={4}>Список обязательных чеклистов</Title>
              </div>
              {(isGetDocLoading || !!docs?.list?.length) && (
                <div className={styles.doc_wrapper}>
                  {docs?.list?.map((doc) => (
                    <div key={doc.id} className={styles.doc_item}>
                      <Link href={doc.link} target="_blank">
                        {doc.name}
                      </Link>
                    </div>
                  ))}
                  {isGetDocLoading && <Skeleton.Input active block />}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  )
}
