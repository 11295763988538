import { UseBaseMutationResult, useMutation, useQueryClient } from "react-query"
import { ICategory } from "../../types"
import { useNavigate } from "react-router-dom"
import { pages } from "../../constants"
import { CategoryService } from "../services/Category.service"
import { CATEGORY } from "../keys"

export function useUpdateCategory(
  id: string,
): UseBaseMutationResult<
  ICategory,
  unknown,
  Omit<ICategory, "id" | "createdAt" | "updatedAt">
> {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  return useMutation((payload) => CategoryService.updateCategory(id, payload), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(CATEGORY)

      navigate(pages.categoryList)
    },
  })
}
