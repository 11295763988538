import Title from "antd/es/typography/Title"
import { MainLayout } from "../../components/MainLayout/MainLayout"
import { SearchOutlined } from "@ant-design/icons"
import styles from "./CategoryListPage.module.css"
import Table, { ColumnsType, TablePaginationConfig } from "antd/es/table"
import { ICategory, ICategoryListPagination } from "../../types"
import { defaultPagination } from "../../constants"
import Search from "antd/es/input/Search"
import { useGetCategory } from "../../queries/category/useGetCategory"
import { useState } from "react"
import debounce from "lodash/debounce"

const columns: ColumnsType<ICategory> = [
  {
    title: "Название (английский)",
    dataIndex: "name",
    key: "name",
    width: 200,
  },
  {
    title: "Название (русский)",
    dataIndex: "displayName",
    key: "displayName",
    width: 200,
  },
  {
    title: "Порядок на сайте",
    dataIndex: "order",
    key: "order",
    width: 200,
  },
]

export const CategoryListPage = () => {
  const [pagination, setPagination] =
    useState<ICategoryListPagination>(defaultPagination)

  const { data: category, isLoading: isGetCategoryLoading } =
    useGetCategory(pagination)

  const handleChangePagination = debounce((value: string) => {
    setPagination((prev) => {
      return { ...prev, search: value }
    })
  }, 200)

  const handleTableChange = ({ current }: TablePaginationConfig) => {
    setPagination((prev) => ({
      ...prev,
      page: (current ?? 1) - 1,
    }))
  }

  return (
    <MainLayout>
      <div className={styles.page}>
        <div className={styles.header}>
          <Title level={4}>Меню</Title>
          <Search
            style={{ width: 300 }}
            name="search"
            placeholder="Поиск по продуктам"
            enterButton="Искать"
            loading={isGetCategoryLoading}
            onSearch={handleChangePagination}
            suffix={<SearchOutlined />}
          />
        </div>
        <div className={styles.content}>
          <Table
            rowKey="id"
            columns={columns}
            dataSource={category?.list}
            loading={isGetCategoryLoading}
            onChange={handleTableChange}
            pagination={{
              current: (category?.pagination.page ?? 0) + 1,
              pageSize: category?.pagination.pageSize,
              total: category?.pagination.total,
            }}
          />
        </div>
      </div>
    </MainLayout>
  )
}
