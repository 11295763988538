import { UseBaseMutationResult, useMutation, useQueryClient } from "react-query"
import { IMenu } from "../../types"
import { useNavigate } from "react-router-dom"
import { pages } from "../../constants"
import { MenuService } from "../services/Menu.service"
import { MENU } from "../keys"

export function useUpdateMenu(
  id: string,
): UseBaseMutationResult<
  IMenu,
  unknown,
  Omit<IMenu, "id" | "createdAt" | "updatedAt">
> {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  return useMutation((payload) => MenuService.updateMenu(id, payload), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(MENU)

      navigate(pages.menu)
    },
  })
}
