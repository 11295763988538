import { Form } from "antd"
import { FC, ReactElement } from "react"
import styles from "./FormCard.module.css"

interface IFormButtonsProps {
  children: ReactElement[]
}

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 24,
      offset: 8,
    },
  },
}

export const FormButtons: FC<IFormButtonsProps> = ({ children }) => {
  return (
    <Form.Item {...tailFormItemLayout}>
      <div className={styles.buttons}>{children}</div>
    </Form.Item>
  )
}
