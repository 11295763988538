import Title from "antd/es/typography/Title"
import styles from "./DocsFormPage.module.css"
import { MainLayout } from "../../components/MainLayout/MainLayout"
import { useParams } from "react-router-dom"
import { CREATE_ID, positionTargets } from "../../constants"
import { IDoc } from "../../types"
import { FormCard } from "../../components/FormCard/FormCard"
import { Button, Checkbox, Form, Input } from "antd"
import { FormButtons } from "../../components/FormCard/FormButtons"
import { Breadcrumbs } from "../../components/Breadcrumbs/Breadcrumbs"
import { useGetDocById } from "../../queries/docs/useGetDocById"
import { useCreateDoc } from "../../queries/docs/useCreateDoc"
import { useUpdateDoc } from "../../queries/docs/useUpdateDoc"

const options = Object.values(positionTargets).map((position) => ({
  label: position.title,
  value: position.key,
}))

export const DocsFormPage = () => {
  const { id } = useParams()

  const isCreate = id === CREATE_ID

  const { data, isLoading: isGetDocByIdLoading } = useGetDocById(id)

  const { mutate: saveDoc, isLoading: isCreateDocLoading } = useCreateDoc()
  const { mutate: updateDoc, isLoading: isUpdateDocLoading } = useUpdateDoc(
    id as string,
  )

  const onFinish = (values: IDoc) => {
    if (isCreate) {
      saveDoc(values)
    } else {
      updateDoc(values)
    }
  }

  return (
    <MainLayout>
      <div className={styles.page}>
        <div className={styles.header}>
          <Breadcrumbs />
          <Title level={4}>
            {isCreate ? "Добавить документ" : "Редактировать документ"}
          </Title>
        </div>
        <div className={styles.content}>
          <FormCard
            key={data?.id}
            disabled={
              isGetDocByIdLoading || isCreateDocLoading || isUpdateDocLoading
            }
            name="doc"
            onFinish={onFinish}
            initialValues={data}
          >
            <Form.Item
              name={"name"}
              label="Название документа"
              rules={[{ required: true }]}
            >
              <Input placeholder="Введите название" />
            </Form.Item>
            <Form.Item
              name={"link"}
              label="Ссылка на документ"
              rules={[{ required: true }]}
            >
              <Input placeholder="https://example.com" />
            </Form.Item>
            <Form.Item
              name={"targets"}
              label="Для кого"
              rules={[{ required: true }]}
            >
              <Checkbox.Group options={options} />
            </Form.Item>
            <FormButtons>
              <Button type="primary" htmlType="submit">
                {isCreate ? "Добавить" : "Сохранить"}
              </Button>
              <Button htmlType="reset">Сбросить</Button>
            </FormButtons>
          </FormCard>
        </div>
      </div>
    </MainLayout>
  )
}
