import { Button, Modal } from "antd"
import { FC } from "react"
import styles from "./MenuPage.module.css"
import { Link } from "react-router-dom"
import { ItemVisibilityStatuses, pages } from "../../constants"
import { useDeleteMenu } from "../../queries/menu/useDeleteMenu"
import { useUpdateMenu } from "../../queries/menu/useUpdateMenu"
import { useGetMenuById } from "../../queries/menu/useGetMenuById"
import clsx from "clsx"
import { deleteMenuModal } from "../../components/Modals/deleteMenuModal"
import { changeMenuStatusModal } from "../../components/Modals/changeMenuStatusModal"

interface IMenuActionProps {
  menuId: string
}

export const MenuAction: FC<IMenuActionProps> = ({ menuId }) => {
  const [modal, contextHolder] = Modal.useModal()
  const { data, isLoading: isGetMenuLoading } = useGetMenuById(menuId)
  const { mutateAsync: deleteMenu, isLoading: isDeleteMenuLoading } =
    useDeleteMenu(menuId)
  const { mutateAsync: updateMenu, isLoading: isUpdateMenuLoading } =
    useUpdateMenu(menuId)

  const isHidden = ItemVisibilityStatuses.hidden.key === data?.status

  const onDelete = () => {
    if (data) {
      const { id, createdAt, updatedAt, status, ...menu } = data

      return updateMenu({
        ...menu,
        status: isHidden
          ? ItemVisibilityStatuses.on_site.key
          : ItemVisibilityStatuses.hidden.key,
      })
    }
  }

  return (
    <div className={styles.actions}>
      <Link to={`${pages.menu}/${menuId}`}>
        <Button className={styles.action_btn} type="link">
          Изменить
        </Button>
      </Link>
      <Button
        className={clsx(styles.action_btn, styles.gray_btn)}
        loading={isUpdateMenuLoading || isGetMenuLoading}
        type="text"
        onClick={changeMenuStatusModal(modal, onDelete)}
      >
        {isHidden ? "Вернуть" : "Скрыть"}
      </Button>
      <Button
        className={styles.action_btn}
        danger
        loading={isDeleteMenuLoading}
        onClick={deleteMenuModal(modal, deleteMenu)}
        type="text"
      >
        Удалить
      </Button>
      {contextHolder}
    </div>
  )
}
