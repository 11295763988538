import { UseBaseMutationResult, useMutation, useQueryClient } from "react-query"
import { DOCS } from "../keys"
import { DocsService } from "../services/Docs.service"

export function useDeleteDoc(
  id: string,
): UseBaseMutationResult<string, unknown, void> {
  const queryClient = useQueryClient()

  return useMutation(() => DocsService.deleteDocs(id), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(DOCS)
    },
  })
}
