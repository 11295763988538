import { UseBaseMutationResult, useMutation } from "react-query"
import { ImageService } from "../services/Image.service"
import type { UploadRequestOption } from "rc-upload/lib/interface"
import { TFileType } from "../../types"
import { AxiosError } from "axios"

export function useUploadFile(
  type: TFileType,
): UseBaseMutationResult<void, AxiosError, UploadRequestOption> {
  return useMutation((payload) => ImageService.uploadFile(payload, type))
}
