import { Button, Modal } from "antd"
import { FC } from "react"
import styles from "./BenefitsPage.module.css"
import { Link } from "react-router-dom"
import { pages } from "../../constants"
import { deleteEmployeeModal } from "../../components/Modals/deleteEmployeeModal"
import { useDeleteBenefits } from "../../queries/benefits/useDeleteBenefits"

interface IBenefitsActionProps {
  benefitId: string
}

export const BenefitsAction: FC<IBenefitsActionProps> = ({ benefitId }) => {
  const [modal, contextHolder] = Modal.useModal()
  const { mutateAsync: deleteBenefit, isLoading } = useDeleteBenefits(benefitId)

  return (
    <div className={styles.actions}>
      <Link to={`${pages.benefits}/${benefitId}`}>
        <Button className={styles.action_btn} type="link">
          Изменить
        </Button>
      </Link>
      <Button
        className={styles.action_btn}
        danger
        loading={isLoading}
        onClick={deleteEmployeeModal(modal, deleteBenefit)}
        type="text"
      >
        Удалить
      </Button>
      {contextHolder}
    </div>
  )
}
