import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import { QueryCache, QueryClient, QueryClientProvider } from "react-query"
import { MyApp } from "./App"

const queryClient = new QueryClient({
  queryCache: new QueryCache({}),
  defaultOptions: {
    queries: {
      staleTime: 1000 * 30, // 30seconds
      cacheTime: 1000 * 30, // 30seconds
      refetchOnMount: "always",
      refetchOnWindowFocus: false,
      refetchOnReconnect: "always",
      refetchInterval: 1000 * 60 * 10, // 10 minutes
      refetchIntervalInBackground: false,
    },
  },
})

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <MyApp />
    </QueryClientProvider>
  </React.StrictMode>,
)
