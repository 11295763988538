import { Button, Modal } from "antd"
import { FC } from "react"
import styles from "./CategoryPage.module.css"
import { Link } from "react-router-dom"
import { pages } from "../../constants"
import { deleteEmployeeModal } from "../../components/Modals/deleteEmployeeModal"
import { useDeleteCategory } from "../../queries/category/useDeleteCategory"

interface ICategoryActionProps {
  categoryId: string
}

export const CategoryAction: FC<ICategoryActionProps> = ({ categoryId }) => {
  const [modal, contextHolder] = Modal.useModal()
  const { mutateAsync: deleteCategory, isLoading: isDeleteCategoryLoading } =
    useDeleteCategory(categoryId)

  return (
    <div className={styles.actions}>
      <Link to={`${pages.category}/${categoryId}`}>
        <Button className={styles.action_btn} type="link">
          Изменить
        </Button>
      </Link>
      <Button
        className={styles.action_btn}
        danger
        loading={isDeleteCategoryLoading}
        onClick={deleteEmployeeModal(modal, deleteCategory)}
        type="text"
      >
        Удалить
      </Button>
      {contextHolder}
    </div>
  )
}
