import Title from "antd/es/typography/Title"
import styles from "./DictPage.module.css"
import { Avatar, Checkbox, Dropdown, Space, Typography, UploadFile } from "antd"
import { DownOutlined, SearchOutlined, UserOutlined } from "@ant-design/icons"
import { IEmployee, IEmployeeListPagination } from "../../types"
import Table, { ColumnsType, TablePaginationConfig } from "antd/es/table"
import dayjs from "dayjs"
import { MainLayout } from "../../components/MainLayout/MainLayout"
import {
  defaultPagination,
  filterBlocks,
  filterPositions,
  positionTargets,
} from "../../constants"
import { useGetEmployees } from "../../queries/employee/useGetEmployees"
import { useState } from "react"
import { DictPageAction } from "./DictPageAction"
import Search from "antd/es/input/Search"
import { CheckboxChangeEvent } from "antd/es/checkbox"

const columns: ColumnsType<IEmployee> = [
  {
    title: "Фото",
    width: 70,
    dataIndex: "images",
    key: "images",
    render: (value: UploadFile[]) => (
      <Avatar src={value?.[0]?.url} size={32} icon={<UserOutlined />} />
    ),
  },
  {
    title: "ФИО",
    dataIndex: "name",
    key: "name",
    width: 300,
    render: (value, employee) => (
      <DictPageAction employee={employee} hideInfo />
    ),
  },
  {
    title: "Позиция",
    dataIndex: "position",
    width: 110,
    key: "position",
    render: (value: keyof typeof positionTargets) =>
      positionTargets[value]?.title,
  },
  {
    title: "ДР",
    dataIndex: "dateOfBirth",
    key: "dateOfBirth",
    width: 100,
    render: (value: string) => dayjs(value).format("DD.MM.YYYY"),
  },
  {
    title: "Телефон",
    dataIndex: "phone",
    key: "phone",
    width: 150,
  },
  {
    title: "Подразделение",
    dataIndex: "block",
    key: "block",
  },
]

export const DictPage = () => {
  const [pagination, setPagination] =
    useState<IEmployeeListPagination>(defaultPagination)

  const { data: employees, isLoading: isGetEmployeesLoading } =
    useGetEmployees(pagination)

  const handleTableChange = ({ current }: TablePaginationConfig) => {
    setPagination((prev) => ({
      ...prev,
      page: (current ?? 1) - 1,
    }))
  }

  const handleChangePagination =
    (field: string) =>
    (event: CheckboxChangeEvent | { key: string } | string) => {
      ;(event as CheckboxChangeEvent)?.preventDefault?.()

      setPagination((prev) => ({
        ...prev,
        [field]:
          (event as CheckboxChangeEvent)?.target?.checked ??
          (event as { key: string })?.key ??
          event,
      }))
    }

  return (
    <MainLayout>
      <div className={styles.page}>
        <div className={styles.header}>
          <Title level={4}>Справочник</Title>
        </div>
        <div className={styles.content}>
          <div className={styles.top}>
            <Title level={5}>Все сотрудники</Title>
            <div className={styles.filters}>
              <Checkbox
                checked={!!pagination.isBirthdayToday}
                onChange={handleChangePagination("isBirthdayToday")}
              >
                Сегодня день рождения
              </Checkbox>
              <Dropdown
                trigger={["click"]}
                menu={{
                  items: filterPositions,
                  onClick: handleChangePagination("position"),
                }}
              >
                <Typography.Text className={styles.dropdown}>
                  <Space>
                    Позиция
                    <DownOutlined />
                  </Space>
                </Typography.Text>
              </Dropdown>

              <Dropdown
                trigger={["click"]}
                menu={{
                  items: filterBlocks,
                  onClick: handleChangePagination("block"),
                }}
              >
                <Typography.Text className={styles.dropdown}>
                  <Space>
                    Подразделение
                    <DownOutlined />
                  </Space>
                </Typography.Text>
              </Dropdown>

              <Search
                style={{ width: 300 }}
                name="search"
                placeholder="Поиск сотрудника"
                enterButton="Искать"
                loading={isGetEmployeesLoading}
                onSearch={handleChangePagination("name")}
                suffix={<SearchOutlined />}
              />
            </div>
          </div>
          <Table
            rowKey="id"
            columns={columns}
            dataSource={employees?.list}
            loading={isGetEmployeesLoading}
            onChange={handleTableChange}
            pagination={{
              current: (employees?.pagination.page ?? 0) + 1,
              pageSize: employees?.pagination.pageSize,
              total: employees?.pagination.total,
            }}
          />
        </div>
      </div>
    </MainLayout>
  )
}
