import { UseBaseMutationResult, useMutation, useQueryClient } from "react-query"
import { MenuService } from "../services/Menu.service"
import { MENU } from "../keys"

export function useDeleteMenu(
  id: string,
): UseBaseMutationResult<string, unknown, void> {
  const queryClient = useQueryClient()

  return useMutation(() => MenuService.deleteMenu(id), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(MENU)
    },
  })
}
