import { QueryObserverResult, useQuery } from "react-query"
import { ICategory } from "../../types"
import { CATEGORY } from "../keys"
import { CategoryService } from "../services/Category.service"
import { CREATE_ID } from "../../constants"

export function useGetCategoryById(
  id?: string,
): QueryObserverResult<ICategory> {
  return useQuery([CATEGORY, id], () => CategoryService.getCategoryById(id), {
    enabled: !!(id && id !== CREATE_ID),
  })
}
