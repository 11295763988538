import { UploadFile } from "antd"
import { BACKEND_URL, request } from "../request"
import type {
  UploadRequestOption,
  UploadRequestError,
} from "rc-upload/lib/interface"
import { TFileType } from "../../types"
import { AxiosError } from "axios"

export const ImageService = {
  async uploadFile(
    { file, onProgress, onSuccess, onError }: UploadRequestOption,
    type: TFileType,
  ): Promise<void> {
    const formData = new FormData()
    formData.append("file", file)

    try {
      const response = await request.post(`/media/${type}`, formData, {
        headers: {
          "content-type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          if (progressEvent?.total) {
            let percent = Math.ceil(
              (progressEvent.loaded / progressEvent.total) * 100,
            )

            onProgress?.({
              percent,
            })
          }
        },
      })

      onSuccess?.({
        uid: response,
        name: response,
        url: `${BACKEND_URL}/media/${type}/${response}`,
        thumbUrl: `${BACKEND_URL}/media/${type}/${response}`,
      })
    } catch (e) {
      if (!(e instanceof AxiosError)) throw e

      let message = "Ошибка! Попробуйте ещё раз!"

      switch (e.code) {
        case "ERR_BAD_REQUEST":
          message = "Слишком большой файл!"
          break
      }

      const error: UploadRequestError = {
        name: "Ошибка отправки",
        message,
      }

      onError?.(error)
      throw new AxiosError(message)
    }
  },

  deleteImage({ uid, response }: UploadFile): Promise<void> {
    return request.delete(`/media/${response?.uid ?? uid}`)
  },
}
