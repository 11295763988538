import {
  CheckSquareOutlined,
  DownSquareOutlined,
  FileSearchOutlined,
  HomeOutlined,
  MenuUnfoldOutlined,
  ReadOutlined,
  TeamOutlined,
} from "@ant-design/icons"
import styles from "./MenuList.module.css"
import { useEffect, useState } from "react"
import { ADMIN_POSITION, pages } from "../../constants"
import clsx from "clsx"
import { Link } from "react-router-dom"
import { TMenuList } from "../../types"
import { useGetProfile } from "../../queries/auth/useGetProfile"

const adminList: TMenuList[] = [
  {
    title: "Новости",
    link: pages.news,
    icon: <HomeOutlined />,
  },
  {
    title: "Сотрудники",
    link: pages.employees,
    icon: <TeamOutlined />,
  },
  {
    title: "Премии / Взыскания",
    link: pages.benefits,
    icon: <DownSquareOutlined />,
  },
  {
    title: "Меню",
    link: pages.menu,
    icon: <ReadOutlined />,
  },
  {
    title: "Категории",
    link: pages.category,
    icon: <MenuUnfoldOutlined />,
  },
]

const employeeList: TMenuList[] = [
  {
    title: "Главная",
    link: pages.profile,
    icon: <HomeOutlined />,
  },
  {
    title: "Чеклисты",
    link: pages.checkList,
    icon: <CheckSquareOutlined />,
  },
  {
    title: "Справочник",
    link: pages.dict,
    icon: <FileSearchOutlined />,
  },
  {
    title: "Меню",
    link: pages.menuList,
    icon: <ReadOutlined />,
  },
  {
    title: "Категории",
    link: pages.categoryList,
    icon: <MenuUnfoldOutlined />,
  },
]

export const MenuList = () => {
  const { data: user } = useGetProfile()
  const [menuList, setMenuList] = useState(employeeList)
  const [activeMenu, setActiveMenu] = useState<string>("")

  useEffect(() => {
    setActiveMenu(window.location.pathname)
  }, [menuList])

  useEffect(() => {
    if ((user?.position as string) === ADMIN_POSITION) {
      setMenuList(adminList)
    } else {
      setMenuList(employeeList)
    }
  }, [user?.position])

  return (
    <div className={styles.menuList}>
      {menuList.map((listItem) => (
        <Link
          className={clsx(
            styles.menuItem,
            activeMenu.includes(listItem.link) && styles.menuItemActive,
          )}
          key={listItem.title}
          to={listItem.link}
        >
          {listItem.icon}
          {listItem.title}
        </Link>
      ))}
    </div>
  )
}
