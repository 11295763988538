import { QueryObserverResult, useQuery } from "react-query"
import { IEmployee } from "../../types"
import { EMPLOYEES } from "../keys"
import { CREATE_ID } from "../../constants"
import { EmployeeService } from "../services/Employee.service"
import dayjs from "dayjs"

export function useGetEmployeeById(
  id?: string,
): QueryObserverResult<IEmployee> {
  return useQuery([EMPLOYEES, id], () => EmployeeService.getEmployeeById(id), {
    enabled: !!(id && id !== CREATE_ID),
    select: (employee) => {
      return {
        ...employee,
        dateOfBirth: dayjs(employee.dateOfBirth),
      }
    },
  })
}
