import { UseBaseMutationResult, useMutation, useQueryClient } from "react-query"
import { CategoryService } from "../services/Category.service"
import { CATEGORY } from "../keys"

export function useDeleteCategory(
  id: string,
): UseBaseMutationResult<string, unknown, void> {
  const queryClient = useQueryClient()

  return useMutation(() => CategoryService.deleteCategory(id), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(CATEGORY)
    },
  })
}
