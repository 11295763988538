import Title from "antd/es/typography/Title"
import { MainLayout } from "../../components/MainLayout/MainLayout"
import Button from "antd/es/button"
import { PlusOutlined, SearchOutlined } from "@ant-design/icons"
import styles from "./BenefitsPage.module.css"
import Table, { ColumnsType, TablePaginationConfig } from "antd/es/table"
import { IBenefit, IBenefitsListPagination, IEmployee } from "../../types"
import dayjs from "dayjs"
import { benefitTypes, defaultPagination, pages } from "../../constants"
import { Link } from "react-router-dom"
import { useGetBenefits } from "../../queries/benefits/useGetBenefits"
import { useState } from "react"
import { BenefitsAction } from "./BenefitsAction"
import Search from "antd/es/input/Search"
import debounce from "lodash/debounce"

const columns: ColumnsType<IBenefit> = [
  {
    title: "ФИО",
    dataIndex: "user",
    key: "user",
    width: 150,
    render: (value: IEmployee) => value.name,
  },
  {
    title: "Тип",
    dataIndex: "type",
    key: "type",
    width: 50,
    render: (value: keyof typeof benefitTypes) => benefitTypes[value].title,
  },
  {
    title: "Дата",
    dataIndex: "date",
    key: "date",
    width: 50,
    render: (value: string) => dayjs(value).format("DD.MM.YYYY"),
  },
  {
    title: "Сумма",
    dataIndex: "amount",
    key: "amount",
    width: 70,
  },
  {
    title: "Описание (повод)",
    dataIndex: "description",
    key: "description",
    width: 200,
  },
  {
    title: "Действия",
    key: "id",
    dataIndex: "id",
    fixed: "right",
    width: 100,
    render: (value: string) => <BenefitsAction benefitId={value} />,
  },
]

export const BenefitsPage = () => {
  const [pagination, setPagination] =
    useState<IBenefitsListPagination>(defaultPagination)

  const { data: benefits, isLoading: isGetBenefitsLoading } =
    useGetBenefits(pagination)

  const handleTableChange = ({ current }: TablePaginationConfig) => {
    setPagination((prev) => ({
      ...prev,
      page: (current ?? 1) - 1,
    }))
  }

  const handleChangePagination = debounce((value: string) => {
    setPagination((prev) => {
      return { ...prev, search: value }
    })
  }, 200)

  return (
    <MainLayout>
      <div className={styles.page}>
        <div className={styles.header}>
          <Title level={4}>Премии / Взыскания</Title>
          <Link to={`${pages.benefits}/create`}>
            <Button type="primary" icon={<PlusOutlined />}>
              Добавить
            </Button>
          </Link>
        </div>
        <div className={styles.content}>
          <Search
            className={styles.input}
            placeholder="Поиск по ФИО"
            enterButton="Искать"
            loading={isGetBenefitsLoading}
            suffix={<SearchOutlined />}
            onSearch={handleChangePagination}
          />
          <Table
            rowKey="id"
            columns={columns}
            dataSource={benefits?.list}
            loading={isGetBenefitsLoading}
            onChange={handleTableChange}
            pagination={{
              current: (benefits?.pagination.page ?? 0) + 1,
              pageSize: benefits?.pagination.pageSize,
              total: benefits?.pagination.total,
            }}
            scroll={{ x: 1500 }}
          />
        </div>
      </div>
    </MainLayout>
  )
}
