import {
  InfiniteData,
  InfiniteQueryObserverResult,
  useInfiniteQuery,
} from "react-query"
import { INewsListPagination, INewsListRes } from "../../types"
import { NEWS } from "../keys"
import { NewsService } from "../services/News.service"

export function useGetNews(
  pagination: INewsListPagination,
): InfiniteQueryObserverResult<INewsListRes> {
  return useInfiniteQuery(
    [
      NEWS,
      pagination.page,
      pagination.pageSize,
      pagination.search,
      pagination.target,
    ],
    ({ pageParam = 0 }) =>
      NewsService.getNews({ ...pagination, page: pageParam }),
    {
      select: (infiniteData): InfiniteData<INewsListRes> => {
        if (!infiniteData.pages?.length) {
          return {
            pages: [],
            pageParams: [],
          }
        }

        return {
          pages: infiniteData.pages,
          pageParams: infiniteData.pages.map((page) => page.pagination.page),
        }
      },
      getNextPageParam: (lastPage) => {
        if (lastPage.pagination) {
          const { page, total = 0, pageSize } = lastPage.pagination

          return total > pageSize * (page + 1) ? page + 1 : false
        }

        return false
      },
    },
  )
}
